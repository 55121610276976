
          @import "src/Core/styles/index.scss";
        
.container {
  gap: 15px;
  @include flex(center);
  @include responsive(padding, 10px, 10px 10px);

  .count {
    flex-shrink     : 0;
    font-weight     : 800;
    min-width       : 32px;
    width           : 32px;
    height          : 32px;
    border-radius   : 32px;
    font-size       : 18px;
    color           : $color-white;
    background-color: $color-primary;
    @include flex(center, center);
  }

  .image {
    flex-shrink  : 0;
    min-width    : 56px;
    width        : 56px;
    height       : 56px;
    border-radius: 56px;
    overflow     : hidden;
  }

  .textContainer {
    flex       : 1;
    font-weight: 700;
    font-size  : 24px;
    word-break : break-word;
    color      : $color-black;
  }
}