
          @import "src/Core/styles/index.scss";
        
.academyListPage {
  flex: 1;

  .academyListPageContainer {

    .academyListPageContent {
      padding-top   : 40px;
      padding-bottom: 96px;
      @include content(1200px, 100%);

      .academyListPageContentList {
        @include responsive(flex-direction, null, column);
        @include responsive(flex-wrap, wrap, nowrap);
        @include responsive(gap, 24px, 12px);
        @include flex();

        >a {
          @include responsive(width, calc(calc(100% / 3) - 16px), 100%);
        }
      }
    }
  }
}