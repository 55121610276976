
          @import "src/Core/styles/index.scss";
        
.container {
  max-width: 100%;

  &.TikTok {
    @supports (-webkit-touch-callout: none) {
      @include responsive(aspect-ratio, null, unset !important);
    }
  }

  .content {
    width : 100% !important;
    height: 100%;

    .innerContent {
      width : 100% !important;
      height: 100%;

      div {
        width: 100% !important;
      }

      blockquote {
        min-width: auto !important;
        max-width: 100% !important;
      }

      @include global {
        blockquote.tiktok-embed {
          margin: 0 !important;
        }
      }

      iframe {
        width : 100%;
        height: 100%;
      }
    }
  }

  &.single {
    // &.Embed {
    //   iframe {
    //     min-height: 428px;
    //   }
    // }

    &.YouTube {
      iframe {
        min-height: 428px;
      }
    }

    &.Vimeo {
      iframe {
        @include responsive(min-height, 428px, 220px);
      }
    }
  }

  @include global {
    .twitter-tweet {
      padding: 0 !important;
      margin : 0 auto 0 auto !important;
    }

    .x-tweet {
      padding: 0 !important;
      margin : 0 auto 0 auto !important;
    }
  }

  &.disabled {
    * {
      pointer-events: none !important;
    }
  }
}