
          @import "src/Core/styles/index.scss";
        
.container {
  gap: 16px;
  aspect-ratio: 1;
  font-size: 16px;
  font-weight: 700;
  @include flex(center, center, column);

  svg {
    font-size: 100px;
  }
}
