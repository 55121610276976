
          @import "src/Core/styles/index.scss";
        
.container {

  &.default {
    @include responsive(padding, 10px 0, 10px 0);
  }

  &.small {
    @include responsive(padding, 5px 0, 2px 0);
  }
}