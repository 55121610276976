
          @import "src/Core/styles/index.scss";
        
.forumListPage {
  flex: 1;

  .forumListPageContainer {

    .forumListPageContent {
      padding-top   : 40px;
      padding-bottom: 96px;
      @include content(1200px, 100%);

      .forumListPageContentList {
        @include responsive(flex-direction, null, column);
        @include responsive(flex-wrap, wrap, nowrap);
        @include responsive(margin-top, 32px, 16px);
        @include responsive(gap, 24px, 12px);
        @include flex();

        >a {
          @include responsive(width, calc((100% / 3) - 16px), 100%);
        }
      }
    }
  }
}