
          @import "src/Core/styles/index.scss";
        
.forumThreadCommentsItemEdit {

  .forumThreadCommentsItemEditor {

    >section {
      padding-top : 0 !important;
      padding-left: 0 !important;
    }

    .forumThreadCommentsItemEditorCancel {
      margin-left: 12px;
      font-size  : 16px;
      padding    : 12px 24px;
    }

    .forumThreadCommentsItemEditorSave {
      margin-left: 12px;
      font-size  : 16px;
      padding    : 12px 24px;
    }
  }
}