
          @import "src/Core/styles/index.scss";
        
.faqListPage {
  flex: 1;

  .faqListPageContainer {

    .faqListPageContent {
      gap           : 16px;
      padding-top   : 40px;
      padding-bottom: 96px;
      @include content(1040px, 100%);
      @include flex(null, null, column);
    }
  }
}