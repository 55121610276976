
          @import "src/Core/styles/index.scss";
        
.threadCard {
  gap             : 12px;
  border-radius   : 24px;
  padding         : 16px 24px;
  background-color: $color-white;
  @include flex(center);

  .threadCardContent {
    flex: 1;
  }

  .threadCardPin {
    width           : 44px;
    height          : 44px;
    font-size       : 16px;
    border-radius   : 44px;
    color           : $color-white;
    background-color: $color-black;
    @include flex(center, center);
  }

  .threadCardTitle {
    font-weight  : 700;
    margin-bottom: 8px;
    gap          : 8px;
    line-height  : 20px;
    font-size    : 16px;
    @include flex(center);

    .threadCardDot {
      width           : 8px;
      height          : 8px;
      border-radius   : 8px;
      background-color: $color-pineappleSalmon;
    }

    .threadCardTitlePin {
      font-size: 14px;
      color    : $color-pineappleSalmon;
    }
  }

  .threadCardSubtitle {
    -webkit-line-clamp: 2;
    font-weight       : 500;
    font-size         : 12px;
    line-height       : 18px;
    margin-bottom     : 12px;
    overflow          : hidden;
    text-overflow     : ellipsis;
    -webkit-box-orient: vertical;
    display           : -webkit-box;
  }

  .threadCardFooter {
    font-weight: 400;
    gap        : 8px;
    font-size  : 12px;
    line-height: 18px;
    @include flex(center);

    .threadCardAvatar {
      width           : 24px;
      height          : 24px;
      border-color    : $color-paleGray;
      background-color: $color-paleGray;

      svg {
        font-size: 12px;
      }
    }
  }
}