
          @import "src/Core/styles/index.scss";
        
.button {
  width : 40px;
  height: 40px;
  @include flex(center, center);

  svg {
    font-size: 20px;
  }

  &.read {
    background-color: rgba($color-black, 0.1);
  }

  &.unread {
    background-color: $color-black;

    svg {
      color: $color-white;
    }

    &:hover {
      background-color: lighten($color-black, 10);
      border-color    : lighten($color-black, 10);
    }

    &:active {
      background-color: lighten($color-black, 15);
      border-color    : lighten($color-black, 15);
    }
  }

  .unreadDot {
    line-height     : 1;
    font-weight     : 800;
    min-width       : 20px;
    height          : 20px;
    border-radius   : 20px;
    font-size       : 13px;
    color           : $color-white;
    background-color: $color-pineappleSalmon;
    @include flex(center, center);
    @include absolute(-4px, -4px);
  }
}

.container {
  width    : 480px;
  max-width: 100%;
  @include responsive(height, null, 100%);

  .content {
    display       : flex;
    flex-direction: column;
    @include responsive(max-height, 80vh, unset);
    @include responsive(height, null, 100%);

    .header {
      @include responsive(justify-content, space-between, flex-start);
      @include responsive(background-color, null, $color-black);
      @include responsive(background-size, 0, unset);
      @include responsive(margin-bottom, 20px, 16px);
      @include responsive(height, null, 70px);
      @include flex(center);

      .logo {
        padding     : 17px;
        border-right: 1px solid rgba($color-blueyGrayTwo, 0.1);
        @include responsive(display, none, initial);
      }

      .title {
        font-weight: 700;
        line-height: 1.25;
        @include flex(center);
        @include responsive(color, $color-black, $color-white);
        @include responsive(text-transform, null, uppercase);
        @include responsive(font-size, 24px, 20px);
        @include responsive(height, null, 100%);

        svg {
          height       : 100%;
          font-size    : 16px;
          padding-left : 15px;
          padding-right: 10px;
          box-sizing   : content-box;
          @include responsive(display, none, initial);
        }
      }

      button {
        font-size      : 14px;
        text-decoration: underline;
        @include responsive(color, null, $color-white);
        @include responsive(margin-right, null, 16px);
        @include responsive(margin-left, null, auto);
      }
    }

    .menu {
      flex-shrink  : 0;
      display      : flex;
      border-radius: 12px;
      @include responsive(padding-left, null, 0);
      @include responsive(margin-bottom, 8px, 4px);
      @include responsive(display, flex, initial);
      @include responsive(border, null, none);

      li {
        flex           : 1;
        justify-content: center;
        @include responsive(margin-right, null, 10px);
        @include responsive(border-radius, 8px, 12px);
        @include responsive(padding, 8px, 8px 16px);
        @include responsive(font-size, 13px, 14px);

        &:first-child {
          @include responsive(margin-left, null, 16px);
        }
      }
    }

    .innerContent {
      flex      : 1;
      overflow-y: auto;
      @include hideScrollbar();
      @include responsive(max-height, 650px, unset);
      @include responsive(padding, null, 0 16px);
    }
  }
}

.popover {
  @include responsive(position, fixed !important, fixed !important);
  @include responsive(top, 80px !important, 0 !important);
  @include responsive(bottom, null, 0 !important);
  @include responsive(right, null, 0 !important);
  @include responsive(left, null, 0 !important);
  @include responsive(margin-left, 50px, unset);

  >div {
    @include responsive(height, null, 100%);
  }

  .popoverContainer {
    padding-bottom: 0;
    @include responsive(border, 2px solid $color-black, none);
    @include responsive(height, null, 100%);
    @include responsive(padding, null, 0);

    &::before {
      display: none;
    }
  }
}