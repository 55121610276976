
          @import "src/Core/styles/index.scss";
        
.container {
  z-index  : 200;
  position : absolute;
  animation: 0.2s ease-out 0s 1 fadeIn;

  .card {
    padding         : 20px;
    border-radius   : 10px;
    background-color: $color-white;

    &::before {
      content  : "";
      width    : 6px;
      height   : 6px;
      transform: translateX(-50%) rotate(45deg);
    }

    .title {
      font-weight   : 800;
      letter-spacing: 1px;
      font-size     : 10px;
      margin-bottom : 10px;
    }
  }

  &.top {
    .card {
      &::before {
        @include absolute(null, null, -3px, 50%);
      }
    }
  }

  &.topLeft {
    .card {
      &::before {
        @include absolute(null, null, -3px, 50%);
      }
    }
  }

  &.bottom {
    .card {
      &::before {
        @include absolute(-3px, null, null, 50%);
      }
    }
  }

  &.left {
    .card {
      &::before {
        @include absolute(20px, -6px, null, null);
      }
    }
  }

  &.right {
    .card {
      &::before {
        @include absolute(20px, null, null, 0);
      }
    }
  }

  &.bottomLeft {
    .card {
      &::before {
        @include absolute(null, -6px, 20px, null);
      }
    }
  }

  &.dark {
    .card {
      color           : $color-white;
      background-color: rgba($color-black, 0.98);

      .title {
        color: rgba($color-white, 0.5);
      }

      &::before {
        background-color: rgba($color-black, 0.8);
      }
    }
  }

  &.light {
    .card {
      .title {
        color: rgba($color-black, 0.5);
      }

      &::before {
        background-color: rgba($color-white, 0.85);
      }
    }
  }

  &.center {
    @include mobile {
      right           : 0;
      bottom          : 0;
      position        : fixed;
      top             : 0 !important;
      left            : 0 !important;
      background-color: rgba($color-black, 0.75);
      @include flex(center, center);

      .card {
        &::before {
          display: none;
        }
      }
    }
  }
}