
          @import "src/Core/styles/index.scss";
        
.header {
  z-index            : 22;
  height             : 80px;
  padding            : 20px 0;
  background-repeat  : repeat;
  background-position: bottom left;
  background-color   : $color-black;
  @include fixed(0, 0, null, 0);

  .headerContent {
    z-index : 2;
    position: relative;
    @include flex(center, space-between);
    @include content(1200px, 100%);

    .logo {
      @include responsive(max-height, null, 20px);
      @include responsive(width, null, auto);
    }

    .headerNavigationMenu {
      @include responsive(display, null, none);
    }

    .headerRight {
      @include responsive(gap, 12px, 8px);
      @include flex(center);

      .circle {
        width           : 40px;
        height          : 40px;
        background-color: rgba($color-black, 0.1);
        @include flex(center, center);

        svg {
          font-size: 20px;
        }
      }
    }
  }
}