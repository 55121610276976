
          @import "src/Core/styles/index.scss";
        
.accountMenuMobileButton {
  background-color: rgba($color-black, 0.1);
  @include responsive(display, none, flex);

  .avatar {
    height: 26px;
    width : 26px;
  }

  &.guest {
    width      : 40px;
    height     : 40px;
    align-items: center;
    @include responsive(display, none, flex);

    svg {
      color: $color-black;
    }
  }

  >div {
    >span:first-child {
      @include responsive(margin-right, null, 0);
    }

    >span:last-child {
      @include responsive(margin-left, null, 8px);
    }
  }
}

.accountMenuMobileOverlay {
  z-index        : 1000;
  flex-direction : column;
  backdrop-filter: blur(31px);
  background     : rgba($color-black, 0.88);
  @include responsive(display, none, flex);
  @include fixed(0, 0, 0, 0);

  .accountMenuMobileOverlayHeader {
    padding      : 16px 20px;
    border-bottom: 2px solid $color-primary;
    @include flex(center, space-between);

    .accountMenuMobileClose {
      width        : 40px;
      height       : 40px;
      border-radius: 40px;
      border       : none;
      color        : $color-white;
      background   : rgba($color-white, 0.10);
      @include flex(center, center);

      svg {
        font-size: 12px;
      }
    }
  }

  .accountMenuMobileOverlayContent {
    padding-top: 32px;

    .accountMenuMobileOverlayItem {
      font-weight: 700;
      font-size  : 16px;
      padding    : 16px 20px;
      color      : $color-white;
    }
  }

  .accountMenuMobileButtons {
    gap           : 12px;
    margin-top    : auto;
    flex-direction: column;
    padding       : 20px 20px 50px 20px;
    @include flex(stretch);

    a {
      flex: 1;
    }

    button {
      font-weight: 600;
      width      : 100%;
      padding    : 10px;
      font-size  : 16px;
    }
  }

  .accountMenuMobileFooter {
    margin-top: auto;
    padding   : 20px 20px 50px 20px;
    @include flex(center, space-between);

    button {
      font-size    : 10px;
      width        : 24px;
      height       : 24px;
      border-radius: 24px;
    }

    .accountMenuMobileUser {
      gap: 12px;
      @include flex(center);

      .accountMenuMobileAvatar {
        width       : 36px;
        height      : 36px;
        font-size   : 18px;
        border-color: $color-limeGreen;
      }

      .accountMenuMobileName {
        font-weight: 700;
        font-size  : 16px;
        color      : $color-white;
      }
    }
  }
}

.logo {
  @include responsive(max-height, null, 20px);
  @include responsive(width, null, auto);
}