
          @import "src/Core/styles/index.scss";
        
.container {
  z-index   : 99999;
  background: rgba($color-black, 0.6);
  @include flex(center, center);
  @include fixed(0, 0, 0, 0);

  .loaderContainer {
    text-align: center;
  }

  .loader {
    color    : $color-white;
    font-size: 24px;
  }

  .info {
    color: $color-white;
  }

  .content {
    font-weight: 800;
    line-height: 1.55;
    background : $color-white;
    border     : 1px solid rgba($color-black, 0.1);
    box-shadow : 0px 34px 74px rgba($color-black, 0.07);
    @include responsive(padding, 15px 20px, 16px 32px);
    @include responsive(border-radius, 10px, 22px);
    @include responsive(font-size, 14px, 18px);
  }
}