
          @import "src/Core/styles/index.scss";
        
.pageHeader {
  background-repeat  : repeat;
  position           : relative;
  background-position: top left;
  background-color   : $color-black;
  @include responsive(padding, 48px 0 60px 0, 24px 0 16px 0);

  .pageHeaderContent {
    @include responsive(align-items, center, flex-start);
    @include responsive(flex-direction, null, column);
    @include responsive(gap, 24px, 24px);
    @include flex(center, space-between);
    @include content(1200px, 100%);

    .pageHeaderText {

      h1 {
        font-weight   : 700;
        gap           : 16px;
        margin-bottom : 12px;
        text-transform: uppercase;
        color         : $color-white;
        @include responsive(line-height, 46px, 25px);
        @include responsive(font-size, 36px, 20px);
        @include flex(flex-start);

        button {
          font-size      : 16px;
          height         : 25px;
          align-items    : center;
          justify-content: center;
          @include responsive(display, none, flex);
        }
      }

      h2 {
        font-weight   : 400;
        letter-spacing: -1%;
        color         : $color-white;
        @include responsive(font-size, 16px, 14px);
        @include responsive(line-height, 32px, 20px);

        &.pageSubtitleSpaced {
          @include responsive(padding-left, null, 32px);
        }
      }
    }

    .pageHeaderExtra {
      flex-shrink: 0;
    }
  }
}