
          @import "src/Core/styles/index.scss";
        
.container {
  gap: 12px;
  @include flex(flex-start);

  .avatar {
    @include responsive(font-size, 16px, 12px);
    @include responsive(height, 32px, 25px);
    @include responsive(width, 32px, 25px);
    border: 2px solid $color-primary;
  }

  .content {
    flex: 1;
    @include responsive(gap, 8px, 4px);
    @include flex(null, null, column);

    .title {
      @include responsive(line-height, 1.37, 1.42);
      @include responsive(font-size, 16px, 14px);

      span {
        font-weight: bold;
      }
    }

    .innerContent {
      border-radius   : 6px;
      background-color: rgba($color-paleGreyFour, 0.4);
      @include responsive(padding, 12px, 8px);

      .comment {
        line-clamp        : 2;
        overflow          : hidden;
        display           : -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        @include responsive(line-height, 1.42, 1.53);
        @include responsive(font-size, 14px, 13px);
      }

      button {
        margin-top: 4px;
        font-size : 14px;

        svg {
          font-size: 10px;
        }
      }
    }

    .footer {
      font-size  : 11px;
      font-weight: 600;
      line-height: 1.27;
      color      : rgba($color-blueyGrayTwo, 1);
    }
  }
}