
          @import "src/Core/styles/index.scss";
        
.forumThreadMenu {
  margin-left: auto;

  .forumThreadMenuButton {
    width    : 44px;
    height   : 44px;
    font-size: 16px;
    @include flex(center, center);
  }

  .forumThreadMenuButtonDanger {
    color: $color-red;
  }

  [role=dialog] {
    min-width: 200px;
  }
}