
          @import "src/Core/styles/index.scss";
        
.modalButtons {
  gap: 16px;
  @include responsive(margin-top, 56px, 28px);
  @include flex(center);

  button {
    flex     : 1;
    padding  : 16px;
    font-size: 16px;
  }
}