
          @import "src/Core/styles/index.scss";
        
.container {
  padding      : 8px;
  border-bottom: 1px solid $color-brightGray;

  .input {

    section {

      span {
        top   : 7px;
        width : 22px;
        height: 22px;
      }

      svg {
        font-size: 12px;
        top      : 17px !important;
      }

      input {
        border          : none;
        padding-top     : 10px;
        padding-bottom  : 10px;
        background-color: $color-paleGreyFour;
        @include responsive(font-size, 12px, 16px);
      }
    }
  }
}