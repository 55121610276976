
          @import "src/Core/styles/index.scss";
        
.forumListItem {
  height          : 100%;
  background-color: $color-white;
  @include responsive(border-radius, 24px, 16px);
  @include responsive(padding, 24px, 16px);
  @include flex(flex-start, null, column);

  .forumListItemIcon {
    border-radius: 60px;
    @include flex(center, center);
    background-color: $color-primary;
    @include responsive(margin-bottom, 32px, 20px);
    @include responsive(font-size, 24px, 20px);
    @include responsive(height, 60px, 48px);
    @include responsive(width, 60px, 48px);

    img {
      filter: invert(100%) brightness(200%) contrast(100%);
    }
  }

  .forumListItemTitle {
    font-weight  : 700;
    margin-bottom: 16px;
    @include responsive(line-height, 30px, 26px);
    @include responsive(font-size, 24px, 20px);
  }

  .forumListItemDescription {
    margin-bottom: 8px;

    .forumListItemDescriptionItem {
      font-weight  : 400;
      margin-bottom: 8px;
      @include responsive(line-height, 26px, 20px);
      @include responsive(font-size, 16px, 14px);
    }
  }

  .forumListItemCount {
    margin-top      : auto;
    font-size       : 14px;
    line-height     : 18px;
    border-radius   : 50px;
    padding         : 6px 10px;
    background-color: rgba($color-black, 0.02);
    border          : 1px solid rgba($color-black, 0.1);
  }
}