
          @import "src/Core/styles/index.scss";
        
.commentSelect {
  z-index : 3;
  position: relative;

  .commentSelectSelection {

    >div>span {
      max-width: 100%;
    }

    span>button {

      >div {
        font-weight    : 700;
        max-width      : 100%;
        font-size      : 16px;
        justify-content: space-between;

        >div {
          flex         : 1;
          overflow     : hidden;
          text-overflow: ellipsis;
        }

        >span {
          min-width  : 32px;
          margin-left: auto !important;
        }
      }
    }
  }
}