
          @import "src/Core/styles/index.scss";
        
.footer {
  background-color: $color-black;
  @include responsive(padding, 72px 0, 48px 0);

  .footerContent {
    gap: 32px;
    @include flex(center, null, column);
    @include content(1200px, 100%);

    .footerNavigation {
      @include responsive(gap, null, 20px);
      @include responsive(flex-direction, null, column);
    }

    .footerDivider {
      height          : 1px;
      width           : 100%;
      background-color: $color-limeGreen;

    }

    .footerSub {
      width: 100%;
      @include responsive(flex-direction, null, column);
      @include responsive(gap, null, 32px);
      @include flex(center, space-between);

      .footerCopyright {
        font-weight: 400;
        font-size  : 16px;
        line-height: 24px;
        color      : $color-white;
      }

      .footerMenu {
        gap: 20px;
        @include responsive(flex-direction, null, column);
        @include flex(center);

        .footerMenuItem {
          font-size  : 16px;
          line-height: 24px;
          color      : $color-white;
        }
      }
    }
  }
}