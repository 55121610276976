
          @import "src/Core/styles/index.scss";
        
.forumThreadPage {
  flex: 1;

  .forumThreadPageContainer {

    .forumThreadPageContent {
      @include content(1040px, 100%);
      @include responsive(padding-bottom, 96px, 44px);
      @include responsive(padding-top, 40px, 25px);
      @include responsive(max-width, null, 100%);

      .forumThreadPageCard {
        background-color: $color-white;
        @include responsive(border-radius, 24px, 0);

        .forumThreadPageCardContent {
          @include responsive(padding, 32px, 16px);

          .forumThreadPageCardHeader {
            gap          : 20px;
            margin-bottom: 32px;
            @include flex(center);

            .forumThreadPageAvatar {
              width           : 60px;
              height          : 60px;
              border          : none;
              background-color: $color-paleGray;

              svg {
                font-size: 30px;
              }
            }

            .forumThreadPageCardUser {

              .forumThreadPageName {
                font-weight  : 700;
                margin-bottom: 6px;
                font-size    : 16px;
              }

              .forumThreadPageDate {
                font-weight: 500;
                font-size  : 14px;
                color      : rgba($color-black, 0.7);
              }
            }
          }

          .forumThreadPageMessage {
            font-size : 16px;
            word-break: break-word;
          }

          .forumThreadPageButtons {
            gap          : 16px;
            margin-top   : 32px;
            margin-bottom: 32px;
            @include flex(center);

            button {
              font-size: 14px;
              padding  : 12px 16px;
            }
          }
        }
      }

      .forumThreadPageFooter {
        margin-top: 68px;
        @include responsive(padding, null, 0 16px);
      }
    }
  }
}