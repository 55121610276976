
          @import "src/Core/styles/index.scss";
        
.forumThreadCreateTags {

  .forumThreadCreateTagsInput {

    .forumThreadCreateTagsAdd {
      font-size    : 16px;
      border-radius: 40px;
      @include responsive(margin-right, null, 3px);
      @include responsive(padding, 10px 20px, 4px 8px);
    }
  }

  .forumThreadCreateTagsList {
    margin-top: 8px;
    gap       : 8px;
    flex-wrap : wrap;
    @include flex();

    .forumThreadCreateTagsListItem {
      font-size       : 16px;
      padding         : 12px 16px;
      background-color: rgba($color-black, 0.06);

      svg {
        font-size: 10px;
      }
    }
  }
}