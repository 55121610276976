
          @import "src/Core/styles/index.scss";
        
.academyCard {
  border-radius: 24px;
  overflow     : hidden;
  position     : relative;
  aspect-ratio : 384 / 460;

  .academyCardImage {
    width : 100%;
    height: 100%;

    img {
      width : 100%;
      height: 100%;
    }
  }

  .academyCardContent {
    padding         : 20px 24px;
    backdrop-filter : blur(15px);
    border-radius   : 0 0 24px 24px;
    background-color: rgba($color-black, 0.72);
    @include absolute(null, 0, 0, 0);

    .academyCardTitle {
      -webkit-line-clamp: 3;
      line-clamp        : 3;
      font-weight       : 700;
      margin-bottom     : 7px;
      font-size         : 20px;
      line-height       : 28px;
      height            : 84px;
      max-width         : 100%;
      overflow          : hidden;
      -webkit-box-orient: vertical;
      display           : -webkit-box;
      color             : $color-white;
    }

    .academyCardFooter {
      gap        : 8px;
      white-space: nowrap;
      @include flex(center);

      .academyCardTag {
        letter-spacing: 1%;
        font-weight   : 500;
        font-size     : 14px;
        overflow      : hidden;
        color         : $color-white;
        text-overflow : ellipsis;
      }
    }
  }

  .academyCardActions {
    z-index: 10;
    @include responsive(display, null, none);
    @include absolute(8px, 8px);

    .academyCardActionsDanger {
      color: $color-red;
    }
  }
}