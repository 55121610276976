
          @import "src/Core/styles/index.scss";
        
.landingHowToCard {
  padding         : 24px;
  border-radius   : 24px;
  height          : 100%;
  background-color: $color-white;

  .landingHowToCardTitle {
    font-weight  : 700;
    font-size    : 24px;
    line-height  : 30px;
    margin-bottom: 16px;
    @include flex(center, space-between);

    button {
      width : 32px;
      height: 32px;
      @include flex(center, center);
    }
  }

  .landingHowToCardSubtitle {
    -webkit-line-clamp: 3;
    font-weight       : 400;
    font-size         : 16px;
    line-height       : 26px;
    overflow          : hidden;
    text-overflow     : ellipsis;
    -webkit-box-orient: vertical;
    display           : -webkit-box;
  }
}