
          @import "src/Core/styles/index.scss";
        
.popover {
  z-index: 600 !important;
}

.row {
  gap           : 10px;
  flex-direction: column;
  @include flex();

  .input {

    input {
      padding: 8px 16px;
    }
  }
}