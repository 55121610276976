
          @import "src/Core/styles/index.scss";
        
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
button {
  margin        : 0;
  padding       : 0;
  border        : 0;
  font-size     : 100%;
  font-family   : inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  user-select          : none;
  overflow-x           : hidden;
  line-height          : normal;
  font-feature-settings: "tnum";
  color                : #151515;
  background-color     : #F8F8F8;
  font-family          : $font-family;
  font-variant         : tabular-nums;

  * {
    box-sizing: border-box;
  }

  *::before,
  *::after {
    box-sizing: border-box;
  }

  #root {
    position: relative;
  }
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-spacing : 0;
  border-collapse: collapse;
}

input,
textarea {
  caret-color: inherit;
  font-family: $font-family;
}

input[type="file"] {
  display: none;
}

button {
  outline         : none;
  cursor          : pointer;
  background-color: transparent;
}

a {
  text-decoration: none;
  color          : inherit;

  &:active {
    color: inherit;
  }
}

:root {
  --app-height: 100%;
}

html {
  -webkit-text-size-adjust   : 100%;
  -ms-text-size-adjust       : 100%;
  -ms-overflow-style         : scrollbar;
  -webkit-tap-highlight-color: rgba(black, 0);
  height                     : 100vh;
  height                     : var(--app-height);
}

img {
  vertical-align: middle;
  border-style  : none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.aria-label {
  border           : 0;
  clip             : rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path        : inset(50%);
  height           : 1px;
  margin           : -1px;
  overflow         : hidden;
  padding          : 0;
  position         : absolute !important;
  width            : 1px;
  word-wrap        : normal !important;
  word-break       : normal;
}