
          @import "src/Core/styles/index.scss";
        
.academyLatest {
  flex-shrink: 0;
  gap        : 16px;
  @include responsive(padding, null, 0 16px);
  @include responsive(width, 300px, 100%);
  @include flex(null, null, column);

  .academyLatestTitle {
    font-weight: 700;
    margin-left: 11px;
    font-size  : 20px;
    line-height: normal;
    position   : relative;

    &::before {
      z-index         : -1;
      content         : '';
      border-radius   : 9px;
      width           : 24px;
      height          : 32px;
      display         : block;
      background-color: $color-pineappleSalmon;
      @include absolute(-3px, null, null, -11px);
    }
  }
}