
          @import "src/Core/styles/index.scss";
        
.sectionHeader {
  position: relative;
  @include responsive(padding-top, 46px, 40px);
  @include responsive(padding-bottom, 36px, 16px);

  .sectionHeaderTag {
    font-weight     : 700;
    border-radius   : 40px;
    color           : $color-white;
    display         : inline-block;
    transform       : rotate(-12.835deg);
    background-color: $color-pineappleSalmon;
    @include responsive(padding, 16px 24px, 12px 16px);
    @include responsive(font-size, 24px, 16px);
    @include absolute(0, null, null, null);

    @media screen and (max-width: 1266px) {
      left: -20px;
    }

    @include responsive(left, -34px, -10px);
  }

  .sectionHeaderTitle {
    z-index      : 2;
    margin-bottom: 4px;
    font-weight  : 700;
    position     : relative;
    @include responsive(line-height, 46px, normal);
    @include responsive(font-size, 36px, 24px);
  }

  .sectionHeaderSubtitle {
    z-index       : 2;
    font-weight   : 400;
    letter-spacing: -1%;
    position      : relative;
    @include responsive(line-height, 32px, 20px);
    @include responsive(font-size, 16px, 14px);
  }
}