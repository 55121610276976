
          @import "src/Core/styles/index.scss";
        
.accountMenuDropdown {
  @include responsive(display, null, none !important);

  .accountMenuButton {
    padding: 6px;

    .accountMenuButtonText {
      @include responsive(display, null, none);
    }
  }
}