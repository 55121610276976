
          @import "src/Core/styles/index.scss";
        
.container {

  >div {
    z-index         : 99;
    border          : none;
    box-shadow      : none;
    background-color: transparent;
    max-width       : calc(100vw - 40px);
    @include responsive(left, null, 50% !important);

    &::before,
    &::after {
      display: none;
    }
  }

  .card {
    border-radius   : 10px;
    width           : 227px;
    transform       : translateX(-50%);
    background-color: rgba($color-black, 0.98);
    box-shadow      : 0 34px 74px 0 rgba($color-black, 0.07);
    @include absolute(40px);

    &::after {
      width       : 0;
      height      : 0;
      content     : '';
      display     : block;
      border-style: solid;
      border-width: 0 5px 5px 5px;
      transform   : translateX(-50%);
      border-color: transparent transparent rgba($color-black, 0.8) transparent;
      @include absolute(-5px, null, null, 50%);
    }

    h4 {
      margin        : 0;
      font-weight   : 900;
      font-size     : 11px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      padding       : 10px 15px 0 15px;
      color         : rgba($color-white, 0.5);

      >button {
        font-size: 12px;

        svg {
          font-size: 12px;
        }
      }
    }

    >ul {
      list-style: none;

      li {
        cursor    : pointer;
        transition: all .1s;
        padding   : 10px 15px;
        color     : $color-white;
        @include flex(center);

        &:not(:last-of-type) {
          border-bottom: 1px solid rgba($color-white, 0.11);
        }

        >span {
          padding         : 6px;
          margin-right    : 5px;
          border-radius   : 20px;
          background-color: rgba($color-white, 0.1);
          @include flex(center, center);
        }

        >h5 {
          font-weight: 700;
          font-size  : 12px;
          color      : $color-white;
        }

        >button {
          font-size  : 10px;
          margin-left: auto;
          padding    : 3px 6px;
        }

        &[role=active] {

          >span {
            background-color: $color-white;
            color           : $color-primary;
          }
        }
      }

      >section {
        padding   : 10px;
        text-align: center;

        >li {
          margin       : 4px;
          padding      : 4px;
          border-radius: 20px;
          display      : inline-block;
          border-bottom: none !important;

          >span {
            margin-right: 0;
            width       : 18px;
            height      : 18px;
            border      : 1px solid transparent;

            &[role=active] {
              border: 1px solid rgba($color-blueyGrayTwo, 0.5);
            }
          }

          &[role=active] {
            background-color: $color-white;

            >span {
              border-color: $color-primary;
            }
          }
        }

        >input {
          width        : 100%;
          outline      : none;
          padding      : 12px;
          border-radius: 10px;
          background   : $color-white;
          caret-color  : $color-primary;
          border       : 1px solid rgba($color-blueyGrayTwo, 0.5);
        }

        >button {
          margin-top: 15px;
          width     : 100%;
        }
      }
    }

    &.activeCard {
      background-color: rgba($color-white, 0.98);

      section {
        background-color: transparent;
      }

      &::after {
        border-color: transparent transparent rgba($color-white, 0.8) transparent;
      }

      h4 {
        color: rgba($color-black, 0.5);
      }
    }
  }

  &.active {

    >div {
      visibility: visible !important;
      transform : translate(-50%) scale(1) !important;
    }
  }
}