
          @import "src/Core/styles/index.scss";
        
.select {

  .selectLabel {
    margin-bottom: 6px;
    font-weight  : 700;
    font-size    : 14px;
  }

  .selectDropdown {
    display : flex;
    position: relative;
    @include responsive(z-index, 2, 21);

    .selectDropdownCheck {
      font-size  : 14px;
      margin-left: auto;
    }

    .selectDropdownButton {
      border-width: 2px;
      font-size   : 14px;
      text-align  : left;
      white-space : nowrap;
      padding     : 7px 7px 7px 16px;

      >div {
        flex: 1;

        span {
          margin-left     : 48px;
          font-size       : 14px;
          border-radius   : 36px;
          color           : $color-white;
          background-color: $color-primary;
          @include flex(center, center);
          @include responsive(width, 36px, 32px);
          @include responsive(height, 36px, 32px);
        }
      }
    }
  }
}