
          @import "src/Core/styles/index.scss";
        
.searchButton {
  display: block;
  @include responsive(flex, null, 1);
  @include responsive(min-width, null, 0);
  @include responsive(width, 460px, 100%);

  button {
    white-space     : nowrap;
    justify-content : flex-start;
    color           : $color-black;
    background-color: $color-white;
    border          : 2px solid $color-black;
    @include responsive(padding, 8px, 7px);

    >div {
      @include responsive(min-width, null, 0);
    }

    .searchButtonText {
      overflow     : hidden;
      text-overflow: ellipsis;
    }

    span {
      border-radius   : 36px;
      background-color: $color-black;
      @include responsive(min-width, 36px, 32px);
      @include responsive(height, 36px, 32px);
      @include responsive(width, 36px, 32px);
      @include inlineFlex(center, center);

      svg {
        font-size: 16px;
        color    : $color-white;
      }
    }
  }
}