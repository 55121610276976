
          @import "src/Core/styles/index.scss";
        
.container {
  gap: 12px;
  @include flex(flex-start);

  .icon {
    border-radius   : 32px;
    color           : $color-primary;
    border          : 1px solid $color-primary;
    background-color: rgba($color-primary, 0.1);
    @include responsive(width, 32px, 25px);
    @include responsive(height, 32px, 25px);
    @include responsive(font-size, 16px, 14px);
    @include flex(center, center);
  }

  .content {
    flex: 1;
    @include responsive(gap, 8px, 4px);
    @include flex(null, null, column);

    .title {
      @include responsive(line-height, 1.37, 1.42);
      @include responsive(font-size, 16px, 14px);
    }

    .innerContent {
      @include responsive(line-height, 1.42, 1.53);
      @include responsive(font-size, 14px, 13px);
    }

    .footer {
      font-size  : 11px;
      font-weight: 600;
      line-height: 1.27;
      color      : rgba($color-blueyGrayTwo, 1);
    }
  }
}