
          @import "src/Core/styles/index.scss";
        
.faqListFilter {
  background-repeat  : repeat;
  background-position: bottom left;
  background-color   : $color-black;

  .faqListFilterContent {
    @include content(1200px, 100%);

    ul,
    span {

      >li {
        color: $color-white;
      }
    }
  }
}