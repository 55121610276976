
          @import "src/Core/styles/index.scss";
        
.container {
  padding: 10px;

  .content {
    @include flex(center, center);

    svg {
      width: 100%;
    }
  }
}