
          @import "src/Core/styles/index.scss";
        
.forumThreadAddComment {
  gap             : 16px;
  border-radius   : 16px;
  margin-top      : 48px;
  background-color: $color-veryLightGray;
  @include responsive(padding, 32px 24px, 16px);
  @include flex(flex-start);

  .forumThreadAddCommentButton {
    margin-left: 12px;
    font-size  : 16px;
    padding    : 12px 24px;
    white-space: nowrap;
  }

  .forumThreadAddCommentAvatar {
    flex-shrink: 0;
    font-size  : 30px;
  }

  .forumThreadAddCommentMessage {
    flex: 1
  }
}