
          @import "src/Core/styles/index.scss";
        
.dropdown {
  position: relative;
  display : inline-flex;

  .trigger {
    flex: 1;
  }

  &.dropdownVisible {
    z-index: 30;
  }
}

.dropdownOverlay {
  opacity         : 0;
  right           : 0;
  z-index         : 10;
  top             : 80%;
  transform-origin: top;
  padding         : 4px;
  width           : 100%;
  border-radius   : 16px;
  overflow-y      : auto;
  min-width       : 320px;
  max-height      : 340px;
  position        : absolute;
  transform       : scaleY(0);
  background-color: $color-white;
  transition      : all 0.2s ease;
  border          : 2px solid $color-black;
  box-shadow      : 0px 4px 6px -2px rgba($color-black, 0.03), 0px 12px 16px -4px rgba($color-black, 0.08);
  @include hideScrollbar();

  .dropdownOverlayHeader {
    font-weight  : 700;
    height       : 48px;
    font-size    : 20px;
    margin-bottom: 12px;
    align-items  : center;
    padding      : 12px 16px;
    @include responsive(display, none, flex);

    .dropdownOverlayClose {
      font-size  : 8px;
      width      : 24px;
      height     : 24px;
      margin-left: auto;
      color      : $color-white;
      @include flex(center, center);
    }
  }

  &.top {
    transform-origin: bottom;
    @include absolute(unset, 0, calc(100% + 5px));
  }

  &.topRight {
    transform-origin: bottom;
    @include absolute(unset, null, calc(100% + 5px), 0);
  }

  &.visible {
    opacity  : 1;
    transform: scaleY(1);
  }

  .list {
    list-style: none;

    >div {
      gap          : 10px;
      font-size    : 16px;
      text-align   : left;
      border-radius: 32px;
      display      : block;
      cursor       : pointer;
      transition   : all .3s;
      padding      : 12px 16px;
      @include flex(center);

      &:hover {
        color           : $color-white;
        background-color: $color-primary;
      }

      &:active {
        color           : $color-white;
        background-color: $color-primary;
      }

      &[aria-selected=true] {
        font-weight     : 500;
        color           : $color-white;
        background-color: $color-primary;
      }

      &[role=link] {
        padding: 0;

        a {
          gap    : 10px;
          width  : 100%;
          padding: 12px 16px;
          @include flex(center);
        }
      }
    }
  }


  &.bottomSheet {
    @include mobile {
      z-index         : 22;
      padding         : 6px;
      border          : none;
      transform-origin: bottom;
      overflow-y      : hidden;
      border-radius   : 20px 20px 0 0;
      @include flex(null, null, column);
      @include fixed(unset, 0, 0, 0);

      >div {
        overflow: hidden;
        @include flex(null, null, column);

        .list {
          overflow-y: auto;
          @include hideScrollbar();

          >div {
            border: none;
          }
        }
      }
    }
  }
}

.dropdownBackground {
  z-index        : 21;
  display        : none;
  backdrop-filter: blur(10.5px);
  background     : rgba($color-black, 0.70);
  @include fixed(0, 0, 0, 0);

  &.visible {
    @include responsive(display, null, block);
  }
}