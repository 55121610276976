
          @import "src/Core/styles/index.scss";
        
.container {
  text-align: center;

  button {
    font-weight  : 900;
    padding      : 15px;
    border-radius: 8px;
    font-size    : 16px;
    position     : relative;
    max-width    : 100%;
    line-height  : 1.25;
    min-width    : 200px;
    text-align   : center;
    word-break   : break-word;
    @include inlineFlex(center, center);

    img {
      width          : 100%;
      height         : 100%;
      object-fit     : cover;
      object-position: center;
      @include absoluteCenter();
    }

    span {
      z-index: 2;
    }

    svg {
      flex-shrink: 0;
      margin-left: 8px;
      color      : inherit;
    }
  }
}