
          @import "src/Core/styles/index.scss";
        
.forumThreadCreatePage {
  flex: 1;

  .forumThreadCreatePageContainer {
    padding: 40px 0;

    .forumThreadCreatePageContent {
      @include content(1040px, 100%);

      form {
        gap: 24px;
        @include flex(null, null, column);

        .forumThreadCreatePageMessage {

          .draftjs {

            >section {
              border-radius   : 20px;
              background-color: $color-white;
              border          : 2px solid $color-black;
            }
          }
        }

        .forumThreadCreatePageButton {
          margin-top: 24px;
          @include flex(center, center);

          button {
            font-weight: 700;
            font-size  : 20px;
            padding    : 20px 60px;
          }
        }
      }
    }
  }
}