@mixin content($web, $tablet: null, $phone: null) {
  width       : $web;
  margin-left : auto;
  margin-right: auto;
  max-width   : calc(100% - 40px);

  @if $tablet !=null {
    @if $phone !=null {
      @include tablet {
        width: $tablet;
      }
    }

    @else {
      @include mobile {
        width: $tablet;
      }
    }
  }

  @if $phone !=null {
    @include phone {
      width: $phone;
    }
  }
}