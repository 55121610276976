
          @import "src/Core/styles/index.scss";
        
.forumSearchPage {
  flex: 1;

  .forumSearchPageContainer {

    .forumSearchPageContent {
      padding-top   : 40px;
      padding-bottom: 96px;
      @include content(1200px, 100%);

      .forumSearchPageFilter {
        gap: 8px;
        @include flex(center, space-between);

        .forumSearchPageSelect {
          flex-shrink: 0;
          @include responsive(flex, null, 1);
          @include responsive(min-width, null, 0);
        }
      }

      .clear {
        padding      : 0;
        margin-right : 6px;
        width        : 24px;
        height       : 24px;
        border-radius: 24px;
        color        : $color-white;
        @include flex(center, center);

        svg {
          font-size: 10px;
        }
      }

      .forumSearchPageContentList {
        margin-top: 40px;
        @include responsive(gap, 16px, 12px);
        @include flex(null, null, column);
      }
    }
  }
}