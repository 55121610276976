
          @import "src/Core/styles/index.scss";
        
.container {

  &.sd-0 {
    @include responsive(display, null, initial);
  }

  &.sm-0 {
    @include responsive(display, null, none !important);
  }

  &.fd-0 {
    @include responsive(display, null, initial);
  }

  &.fm-0 {
    @include responsive(display, null, none !important);
  }

  &.fd-auto {
    flex: auto;
  }

  &.fm-auto {
    @include responsive(flex, null, auto !important);
  }

  @for $i from 1 to 25 {
    &.sd-#{$i} {
      max-width: #{100 / 24 * $i}#{"%"};
      flex     : 0 0 #{100 / 24 * $i}#{"%"};
    }

    &.sm-#{$i} {
      @include responsive(max-width, null, #{100 / 24 * $i}#{"%"} !important);
      @include responsive(flex, null, #{100 / 24 * $i}#{"%"} !important);
    }

    &.fd-#{$i} {
      flex : #{$i};
    }

    &.fm-#{$i} {
      @include responsive(flex, null, #{$i} !important);
    }
  }
}