
          @import "src/Core/styles/index.scss";
        
.howToSidebarItem {
  gap: 16px;
  @include flex(null, null, column);

  .howToSidebarItemLabel {
    border-radius   : 16px;
    height          : 60px;
    cursor          : pointer;
    transition      : all .2s;
    padding         : 16px 20px;
    background-color: $color-white;
    @include flex(center, space-between);

    .howToSidebarItemArrow {
      width           : 28px;
      height          : 28px;
      border-radius   : 28px;
      font-size       : 14px;
      color           : $color-white;
      background-color: $color-primary;
      @include flex(center, center);
    }
  }

  .howToSidebarItemList {
    padding-left: 20px;
    gap         : 10px;
    @include flex(null, null, column);
  }

  &.collapsed,
  &.active {

    >.howToSidebarItemLabel {
      font-weight     : 700;
      height          : 52px;
      padding         : 16px;
      color           : $color-white;
      background-color: $color-primary;

      .howToSidebarItemArrow {
        background-color: $color-white;
        color           : $color-primary;
      }
    }
  }
}