
          @import "src/Core/styles/index.scss";
        
.container {
  gap: 10px;
  @include flex(null, center);

  &.edit {
    gap: 15px;
  }

  .item {
    min-width: 0;

    >div {
      height: 100%;

      >div {
        height: 100%;
      }
    }
  }

  &.tile {
    grid-auto-rows       : 1fr;
    display              : grid;
    grid-auto-flow       : unset;
    grid-template-columns: 1fr 1fr;

    .item {
      height: 100%;

      iframe {
        min-height: unset;
      }

      &:nth-child(3n - 1) {
        height  : 100%;
        grid-row: span 2;

        div {
          height: 100%;

          >span {
            display: block !important;
            height : 100% !important;

            >span {
              height: 100% !important;

              img {
                height: 100% !important;
              }
            }
          }
        }
      }
    }
  }

  &.column {
    @include responsive(flex-direction, null, column);
  }
}