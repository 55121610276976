
          @import "src/Core/styles/index.scss";
        
.noComment {
  gap             : 12px;
  padding         : 20px;
  font-weight     : bold;
  border-radius   : 40px;
  background-color: $color-black;
  color           : $color-white;
  @include flex(center, center);
  @include responsive(font-size, 16px, 14px);

  svg {
    font-size: 24px;
  }
}