
          @import "src/Core/styles/index.scss";
        
.container {
  position: relative;

  .content {
    z-index         : 100;
    font-size       : 24px;
    background-color: rgba($color-white, 0.5);
    color           : rgba($color-blueyGrayTwo, 0.5);
    @include flex(center, center);
    @include absolute(0, 0, 0, 0);

    &.hasContent {
      font-size  : 13px;
      font-weight: 700;
      font-style : italic;
    }
  }
}