
          @import "src/Core/styles/index.scss";
        
.container {
  line-height: 1.5;

  ul,
  ol {
    padding-left: 20px;
  }

  a {
    span {
      text-decoration: underline;
      color          : unset;
    }
  }
}