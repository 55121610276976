
          @import "src/Core/styles/index.scss";
        
.header {
  z-index   : 20;
  height    : 80px;
  padding   : 20px 0;
  transition: all .2s;

  @include fixed(0, 0, null, 0);

  .headerContent {
    z-index : 2;
    position: relative;
    @include flex(center, space-between);
    @include content(1200px, 100%);

    .logo {
      @include responsive(max-height, null, 20px);
      @include responsive(width, null, auto);
    }

    .headerNavigationMenu {
      @include responsive(display, null, none);

      a {
        color: $color-white;
      }
    }

    .headerRight {
      gap: 12px;
      @include flex(center);

      .circle {
        width           : 40px;
        height          : 40px;
        border-color    : transparent;
        background-color: rgba($color-white, 0.1);
        @include flex(center, center);

        svg {
          font-size: 20px;
          color    : $color-white;
        }
      }

      .accountMenu {
        border-color    : transparent;
        color           : $color-white;
        background-color: rgba($color-white, 0.1);
      }

      .mobileAccountMenu {
        border-color    : transparent;
        color           : $color-white;
        background-color: rgba($color-white, 0.1);
      }
    }
  }

  .headerBg {
    z-index       : -1;
    width         : 100%;
    height        : 100%;
    pointer-events: none;
    @include absolute(0, 0, 0, 0);
  }

  &.scrolled {
    backdrop-filter: blur(15px);
    border-bottom  : 2px solid $color-primary;
    background     : rgba($color-black, 0.70);
  }
}