
          @import "src/Core/styles/index.scss";
        
.container {
  gap: 12px;
  @include flex(flex-start);

  .icon {
    border-radius   : 32px;
    color           : $color-primary;
    border          : 1px solid $color-primary;
    background-color: rgba($color-primary, 0.1);
    @include responsive(width, 32px, 25px);
    @include responsive(height, 32px, 25px);
    @include responsive(font-size, 16px, 12px);
    @include flex(center, center);
  }

  .content {
    flex: 1;
    @include responsive(gap, 8px, 4px);
    @include flex(null, null, column);

    .title {
      @include responsive(line-height, 1.37, 1.42);
      @include responsive(font-size, 16px, 14px);

      span {
        font-weight: bold;
      }
    }

    .innerContent {
      @include flex(center);
      border-radius   : 6px;
      gap             : 8px;
      background-color: rgba($color-paleGreyFour, 0.4);
      @include responsive(padding, 12px, 8px);

      .image {
        img {
          border-radius: 4px;
          object-fit   : cover;
          aspect-ratio : 92 / 64;
          width        : 92px;
          height       : auto;
        }
      }

      .text {
        @include flex(flex-start, null, column);

        .user {
          gap        : 4px;
          padding    : 4px;
          font-size  : 10px;
          font-weight: 800;
          @include flex(center, center);
          background-color: $color-paleGreyFour;
          border-radius   : 6px;

          .avatar {
            width    : 16px;
            height   : 16px;
            font-size: 8px;
            border   : 1px solid $color-white;
          }

          >svg {
            margin-left : 4px;
            margin-right: 4px;
          }
        }

        .textTitle {
          overflow          : hidden;
          line-clamp        : 2;
          display           : -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          font-weight       : 800;
          @include responsive(line-height, 1.42, 1.53);
          @include responsive(font-size, 14px, 13px);
        }
      }

    }

    .footer {
      font-size  : 11px;
      font-weight: 600;
      line-height: 1.27;
      color      : rgba($color-blueyGrayTwo, 1);
    }
  }
}