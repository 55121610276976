
          @import "src/Core/styles/index.scss";
        
.modal {
  z-index         : 201;
  overflow        : auto;
  text-align      : center;
  backdrop-filter : blur(10.5px);
  background-color: rgba($color-black, 0.7);
  animation       : 0.2s normal 0s 1 fadeIn;
  @include fixed(0, 0, 0, 0);
  @include hideScrollbar();

  &::before {
    width         : 0;
    content       : "";
    height        : 100%;
    vertical-align: middle;
    display       : inline-block;
  }

  .modalBackdrop {
    z-index: 1;
    @include absolute(0, 0, 0, 0);
  }

  .modalCard {
    top             : 0;
    z-index         : 2;
    border-radius   : 24px;
    transition      : none;
    text-align      : left;
    width           : 480px;
    vertical-align  : middle;
    margin          : 20px 0;
    position        : relative;
    display         : inline-block;
    background-color: $color-white;
    max-width       : calc(100% - 40px);
    padding         : 32px 24px 24px 24px;

    .modalCardIcon {
      margin-bottom: 24px;
      font-size    : 60px;
      display      : flex;
    }

    .modalCardTitle {
      font-weight  : 700;
      font-size    : 24px;
      line-height  : 30px;
      margin-bottom: 16px;
    }

    .modalCardSubtitle {
      letter-spacing: 2%;
      font-weight   : 400;
      font-size     : 16px;
      line-height   : 26px;
    }
  }
}