
          @import "src/Core/styles/index.scss";
        
.threadSortSelect {

  >div {
    >span {
      @include responsive(min-width, null, 0);
    }
  }

  button {
    @include responsive(padding-left, null, 12px !important);

    >div {
      @include responsive(min-width, null, 0);

      >div {
        overflow     : hidden;
        text-overflow: ellipsis;
        @include responsive(flex, null, 1);
      }

      >span {
        @include responsive(min-width, null, 32px);
        @include responsive(margin-left, null, 0 !important);
      }
    }
  }
}