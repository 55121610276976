
          @import "src/Core/styles/index.scss";
        
.navigationMenu {
  gap: 32px;
  @include flex(center, center);

  .navigationMenuItem {
    font-weight  : 500;
    font-size    : 16px;
    line-height  : 20px;
    border-radius: 20px;
    transition   : all .2s;
    padding      : 7px 12px;
    color        : $color-white;

    &:hover {
      background-color: rgba($color-white, 0.1);
    }

    &.active {
      font-weight     : 700;
      color           : $color-white;
      background-color: $color-primary;
    }
  }

  &.footer {
    gap: 44px;

    .navigationMenuItem {
      color: $color-white;

      &:hover {
        background-color: rgba($color-white, 0.1);
      }

      &.active {
        font-weight     : 500;
        background-color: unset;
        color           : $color-white;
      }
    }
  }
}