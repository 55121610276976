
          @import "src/Core/styles/index.scss";
        
.container {
  display  : flex;
  flex-flow: row wrap;

  @for $i from 1 to 21 {
    &.gd-#{$i * 2} {
      margin-left : #{-$i}#{"px"};
      margin-right: #{-$i}#{"px"};
      row-gap     : #{$i * 2}#{"px"};

      >div {
        padding-left : #{$i}#{"px"};
        padding-right: #{$i}#{"px"};
      }
    }

    &.gm-#{$i * 2} {
      @include responsive(row-gap, null, #{$i * 2}#{"px"} !important);
      @include responsive(margin-left, null, #{-$i}#{"px"} !important);
      @include responsive(margin-right, null, #{-$i}#{"px"} !important);

      >div {
        @include responsive(padding-left, null, #{$i}#{"px"} !important);
        @include responsive(padding-right, null, #{$i}#{"px"} !important);
      }
    }
  }
}