
          @import "src/Core/styles/index.scss";
        
.landingForum {
  padding-top     : 44px;
  padding-bottom  : 72px;
  background-color: $color-veryLightGray;

  .landingForumContent {
    @include content(1200px, 100%);

    .landingForumContentList {
      @include responsive(flex-direction, null, column);
      @include responsive(flex-wrap, wrap, nowrap);
      @include responsive(gap, 24px, 12px);
      @include flex();

      >a {
        @include responsive(width, calc((100% / 3) - 16px), 100%);

        &:nth-last-child(-n+3) {
          @include responsive(display, null, none);
        }
      }
    }

    .landingForumButton {
      margin-top: 32px;
      text-align: center;
      @include responsive(display, none, block);

      button {
        @include responsive(font-size, 20px, 16px);
        @include responsive(padding, 20px 48px, 16px 24px);

        svg {
          @include responsive(font-size, null, 14px);
        }
      }
    }
  }
}