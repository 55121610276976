
          @import "src/Core/styles/index.scss";
        
.academyPage {
  flex: 1;

  .academyPageContent {
    gap           : 32px;
    padding-top   : 40px;
    padding-bottom: 96px;
    @include content(1200px, 100%);
    @include responsive(align-items, flex-start, unset);
    @include responsive(flex-direction, null, column);
    @include responsive(max-width, null, 100%);
    @include flex();

    .academyPageCard {
      flex            : 1;
      background-color: $color-white;
      @include responsive(border-radius, 24px, 0);

      .academyPageCardContent {
        @include responsive(padding, 24px, 16px);

        .academyPageCardTitle {
          font-weight: 700;
          @include responsive(margin-bottom, 24px, 12px);
          @include responsive(line-height, 46px, 30px);
          @include responsive(font-size, 36px, 24px);
        }
      }
    }
  }
}