
          @import "src/Core/styles/index.scss";
        
@mixin socialButton($color, $shadow, $text: #ffffff) {
  &.type1 {
    padding      : 2px;
    border-radius: 50px;
    background   : $color;

    .content {
      color: $text;
    }
  }

  &.type2 {
    padding      : 2px;
    border-radius: 50px;
    background   : $color;

    .content {
      color           : $color-black;
      background-color: $color-white;

      svg {
        color: $color;
      }
    }
  }

  &.type3 {
    padding      : 2px;
    border-radius: 4px;
    background   : $color;
    box-shadow   : 0px 4px 0px $shadow;

    .content {
      color: $text;
    }
  }

  &.type4 {
    border-radius: 4px;
    background   : $color;
    padding      : 2px 2px 6px 2px;

    .content {
      color           : $color-black;
      background-color: $color-white;

      svg {
        color: $color;
      }
    }
  }
}

.container {
  text-align: center;

  .button {
    max-width: 100%;
    display  : inline-flex;

    .content {
      font-weight  : 900;
      width        : 100%;
      padding      : 15px;
      font-size    : 16px;
      max-width    : 100%;
      line-height  : 1.25;
      min-width    : 200px;
      text-align   : center;
      border-radius: inherit;
      @include flex(center, center);

      .text {
        word-break: break-word;
      }

      .icon {
        flex-shrink : 0;
        margin-right: 6px;
        font-size   : 20px;
        display     : inline-flex;
      }
    }

    &.twitter {
      @include socialButton(black, #184a69);
    }

    &.instagram {
      @include socialButton(linear-gradient(46.38deg, #fec61c 2.29%, #ec001b 52.3%, #c0029d 100%), #9f2c21);
    }

    &.facebook {
      @include socialButton(#4267b2, #1e386d);
    }

    &.youtube {
      @include socialButton(#ff0000, #6d1a1a);
    }

    &.snapchat {
      @include socialButton(#fffc00, #7c7b1e, $color-black);
    }

    &.twitch {
      @include socialButton(#6c2498, #481866);
    }

    &.tiktok {
      @include socialButton(linear-gradient(135deg, #01f2e9 0%, #fe004d 100%), #68244f);
    }
  }
}