
          @import "src/Core/styles/index.scss";
        
.threadCreateButton {
  font-weight: 600;
  font-size  : 16px;
  padding    : 16px 24px;

  svg {
    font-size: 16px;
  }
}