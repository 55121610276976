
          @import "src/Core/styles/index.scss";
        
.howToContent {
  flex            : 1;
  background-color: $color-white;
  @include responsive(border-radius, 24px, 0);

  .howToContentContainer {
    @include responsive(padding, 32px, 24px 16px);

    .howToContentTitle {
      font-weight: 700;
      @include responsive(margin-bottom, 24px, 12px);
      @include responsive(line-height, 46px, 30px);
      @include responsive(font-size, 36px, 24px);
      @include flex(center, space-between);

      a {
        margin-left: auto;
      }
    }
  }
}