
          @import "src/Core/styles/index.scss";
        
.container {
  max-width      : 100%;
  font-weight    : bold;
  border-radius  : 10px;
  text-align     : center;
  justify-content: center;
  transition     : all 0.3s;
  position       : relative;
  display        : inline-flex;
  border         : 1px solid transparent;
  @include responsive(padding, 10px 30px, 10px 25px);
  @include responsive(font-size, 16px, 14px);

  .content {
    @include inlineFlex(center, center);
  }

  &.auto {
    @include responsive(padding, 10px, 8px);
  }

  &.block {
    width: 100%;
  }

  &.circle {
    border-radius: 50px;
    padding      : 8px !important;
  }

  &.loading {
    .content {
      opacity: 0;
    }
  }

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &.primary {
    color           : $color-white;
    background-color: $color-primary;

    &:hover {
      background-color: darken($color-primary, 10%);
    }

    &:active {
      background-color: darken($color-primary, 15%);
    }

    &:disabled {
      color           : rgba($color-blueyGrayTwo, 0.6);
      background-color: rgba($color-blueyGrayTwo, 0.1);
    }
  }

  &.primaryText {
    color           : $color-primary;
    background-color: $color-white;
    border-color    : rgba($color-blueyGrayTwo, 0.1);

    &:hover {
      background-color: darken($color-white, 10%);
    }

    &:active {
      background-color: darken($color-white, 15%);
    }

    &:disabled {
      color           : rgba($color-blueyGrayTwo, 0.6);
      background-color: rgba($color-blueyGrayTwo, 0.1);
    }
  }

  &.secondary {
    color           : $color-black;
    background-color: $color-white;
    border-color    : rgba($color-blueyGrayTwo, 0.5);

    &:hover {
      background-color: darken($color-white, 10%);
      border-color    : rgba($color-blueyGrayTwo, 0.7);
    }

    &:active {
      background-color: darken($color-white, 15%);
      border-color    : rgba($color-blueyGrayTwo, 0.9);
    }

    &:disabled {
      background-color: $color-white;
      color           : rgba($color-black, 0.2);
      border-color    : rgba($color-blueyGrayTwo, 0.2);
    }
  }

  &.tertiary {
    color           : $color-primary;
    background-color: rgba($color-primary, 0.1);

    &:hover {
      background-color: rgba($color-primary, 0.2);
    }

    &:active {
      background-color: rgba($color-primary, 0.25);
    }

    &:disabled {
      color           : rgba($color-primary, 0.3);
      background-color: rgba($color-primary, 0.04);
    }
  }

  &.quarternary {
    color           : $color-primary;
    background-color: rgba($color-white, 0.98);

    &:hover {
      background-color: rgba($color-white, 0.75);
    }

    &:active {
      background-color: rgba($color-white, 0.6);
    }

    &:disabled {
      background-color: rgba($color-white, 0.4);
      color           : rgba($color-primary, 0.3);
    }
  }

  &.danger {
    color           : $color-white;
    background-color: $color-pineappleSalmon;

    &:hover {
      background-color: darken($color-pineappleSalmon, 10%);
    }

    &:active {
      background-color: darken($color-pineappleSalmon, 15%);
    }

    &:disabled {
      color           : rgba($color-blueyGrayTwo, 0.6);
      background-color: rgba($color-blueyGrayTwo, 0.1);
    }
  }

  &.white {
    color           : $color-black;
    background-color: $color-white;

    &:hover {
      background-color: darken($color-white, 10%);
    }

    &:active {
      background-color: darken($color-white, 15%);
    }

    &:disabled {
      color           : rgba($color-blueyGrayTwo, 0.6);
      background-color: rgba($color-blueyGrayTwo, 0.1);
    }
  }

  &.whiteOverlay {
    color           : $color-white;
    background-color: rgba($color-white, 0.3);

    &:hover {
      background-color: rgba($color-white, 0.4);
    }

    &:active {
      background-color: rgba($color-white, 0.5);
    }

    &:disabled {
      color           : rgba($color-blueyGrayTwo, 0.6);
      background-color: rgba($color-blueyGrayTwo, 0.1);
    }
  }

  &.whiteOutline {
    background-color: transparent;
    color           : $color-white;
    border-color    : $color-white;

    &:hover {
      color       : darken($color-white, 10%);
      border-color: darken($color-white, 10%);
    }

    &:active {
      color       : darken($color-white, 15%);
      border-color: darken($color-white, 15%);
    }

    &:disabled {
      color           : rgba($color-blueyGrayTwo, 0.6);
      background-color: rgba($color-blueyGrayTwo, 0.1);
    }
  }

  &.dark {
    background-color: $color-black;
    color           : $color-white;

    &:hover {
      background-color: darken($color-black, 10%);
    }

    &:active {
      background-color: darken($color-black, 15%);
    }

    &:disabled {
      color           : rgba($color-blueyGrayTwo, 0.6);
      background-color: rgba($color-blueyGrayTwo, 0.1);
    }
  }

  &.link {
    padding         : 0;
    border-radius   : 0;
    width           : auto;
    border          : none;
    background-color: transparent;
    color           : $color-primary;

    &:hover {
      color: darken($color-primary, 10%);
    }

    &:active {
      color: darken($color-primary, 15%);
    }

    &:disabled {
      color: rgba($color-blueyGrayTwo, 0.6);
    }
  }

  &.text {
    padding         : 0;
    border-radius   : 0;
    width           : auto;
    border          : none;
    background-color: transparent;
    color           : $color-black;

    &:hover {
      color: darken($color-black, 10%);
    }

    &:active {
      color: darken($color-black, 15%);
    }

    &:disabled {
      color: rgba($color-blueyGrayTwo, 0.6);
    }
  }

  &.whiteText {
    padding         : 0;
    border-radius   : 0;
    width           : auto;
    border          : none;
    background-color: transparent;
    color           : $color-white;

    &:hover {
      color: darken($color-white, 10%);
    }

    &:active {
      color: darken($color-white, 15%);
    }

    &:disabled {
      color: rgba($color-blueyGrayTwo, 0.6);
    }
  }

  &.dangerText {
    padding         : 0;
    border-radius   : 0;
    width           : auto;
    border          : none;
    background-color: transparent;
    color           : $color-pineappleSalmon;

    &:hover {
      color: darken($color-pineappleSalmon, 10%);
    }

    &:active {
      color: darken($color-pineappleSalmon, 15%);
    }

    &:disabled {
      color: rgba($color-blueyGrayTwo, 0.6);
    }
  }

  &.quinary {
    background-color: $color-paleGreyFour;

    &:hover {
      background-color: darken($color-paleGreyFour, 10%);
    }

    &:active {
      background-color: darken($color-paleGreyFour, 15%);
    }

    &:disabled {
      color           : rgba($color-blueyGrayTwo, 0.6);
      background-color: rgba($color-blueyGrayTwo, 0.1);
    }
  }

  &.senary {
    color           : $color-white;
    background-color: $color-algaeGreen;

    &:hover {
      background-color: darken($color-algaeGreen, 10%);
    }

    &:active {
      background-color: darken($color-algaeGreen, 15%);
    }

    &:disabled {
      color           : rgba($color-blueyGrayTwo, 0.6);
      background-color: rgba($color-blueyGrayTwo, 0.1);
    }
  }

  .before {
    font-size   : 16px;
    margin-right: 10px;
    @include inlineFlex();
  }

  .after {
    font-size  : 16px;
    margin-left: 10px;
    @include inlineFlex();
  }

  .loading {
    z-index      : 2;
    color        : inherit;
    border-radius: inherit;
    @include absolute(0, 0, 0, 0);
    @include flex(center, center);

    >svg {
      margin-right: unset !important;
    }
  }
}