
          @import "src/Core/styles/index.scss";
        
.howToPage {
  flex: 1;

  .howToPageContainer {

    .howToPageContent {
      gap           : 32px;
      padding-top   : 40px;
      padding-bottom: 96px;
      @include responsive(max-width, null, 100%);
      @include content(1200px, 100%);
      @include flex(flex-start);
    }
  }
}