
          @import "src/Core/styles/index.scss";
        
.container {
  overflow: hidden;
  cursor  : pointer;
  position: relative;
  border  : 4px solid $color-black;

  .imageContainer {
    &.img {
      z-index : 2;
      width   : 100%;
      height  : auto;
      position: relative;
    }

    img {
      z-index   : 2 !important;
      width     : 100% !important;
      height    : auto !important;
      min-height: unset !important;
    }
  }

  .content {
    .innerContent {
      width: 100%;
      @include flex(center, center);
    }
  }

  .user {
    opacity   : 0;
    z-index   : 4;
    padding   : 20px;
    transition: all .2s;
    @include flex(center);
    @include absolute(null, 0, 0, 0);
    background: linear-gradient(180deg, rgba($color-black, 0.47) 0%, rgba($color-black, 0.92) 100%);

    .avatar {
      width       : 36px;
      height      : 36px;
      border-color: $color-limeGreen;
    }

    .username {
      font-weight: 700;
      margin-left: 12px;
      font-size  : 16px;
      color      : $color-white;
    }

    .external {
      margin-left: auto;

      svg {
        font-size: 24px;
        color    : $color-white;
      }
    }
  }

  &:hover {
    .user {
      opacity: 1;
    }
  }

  a {
    display: block;
    height : 100%;

    img {
      width : 100%;
      height: 100%;
    }
  }

  &.gif {
    @include flex(center, center);

    a {
      z-index : 2;
      width   : 100%;
      position: relative;
      @include flex(center, center);

      .imageContainer {
        @include flex(center, center);
      }
    }

    .gifBg {
      width   : 100% !important;
      height  : 100% !important;
      position: absolute !important;
      @include absolute(0, 0, 0, 0);

      img {
        filter         : blur(8px) !important;
        object-fit     : cover !important;
        object-position: center !important;
      }
    }
  }

  &.modalContainer {
    max-height: 80vh;
  }

  &.single {
    width: unset;

    .imageContainer {
      width  : 100%;
      height : 100%;
      display: block;

      >span {
        width : 100% !important;
        height: 100% !important;
      }
    }
  }

  &.tile {
    >a {
      >span {
        height : 100%;
        display: block;

        img {
          height    : 100% !important;
          object-fit: cover !important;
        }
      }
    }
  }
}

.modal {
  @include responsive(border-radius, 20px !important, 10px !important);

  .user {
    @include flex(center);
    @include responsive(margin-bottom, 20px, 15px);

    .avatar {
      width : 36px;
      height: 36px;
    }

    .username {
      font-weight: 700;
      margin-left: 12px;
      font-size  : 16px;
    }

    .external {
      margin-left: auto;

      svg {
        font-size: 24px;
      }
    }
  }

  .modalContainer {
    margin        : auto;
    flex-direction: column;
    overflow      : visible;
    border        : none;
    border-radius : 0;
    @include flex(center, center);

    img {
      border-radius: 16px;
      border       : 4px solid $color-black;
    }
  }

  main {
    padding: 15px !important;

    img {
      width     : 100%;
      height    : auto;
      max-width : 100%;
      max-height: 100%;
    }
  }
}

.closeButton {
  margin-top: 20px;

  button {
    font-size: 16px;
    padding  : 16px;
  }
}