
          @import "src/Core/styles/index.scss";
        
.text {
  z-index    : 3;
  font-weight: 700;
  word-break : break-word;
  height     : auto !important;
  @include responsive(line-height, 26px, 28px);
  @include responsive(font-size, 20px, 22px);

  &.type1 {

    padding   : 15px;
    color     : $color-white;
    background: linear-gradient(180deg, rgba($color-black, 0) 53.99%, $color-black 100%);
    @include absolute(0, 0, 0, 0);
    @include flex(flex-end);
  }

  &.type2 {
    padding   : 15px;
    color     : $color-white;
    background: linear-gradient(0deg, rgba($color-black, 0) 53.99%, $color-black 100%);
    @include absolute(0, 0, 0, 0);
    @include flex(flex-start);
  }

  &.type3 {
    @include responsive(font-size, 20px, 10px);

    width           : 40%;
    padding         : 15px;
    color           : $color-white;
    background-color: rgba($color-black, 0.35);
    @include absolute(0, null, 0, 0);
    @include flex(flex-start);
  }

  &.type4 {
    width           : 40%;
    padding         : 15px;
    color           : $color-white;
    background-color: rgba($color-black, 0.35);
    @include absolute(0, null, 0, 0);
    @include flex(center);
  }

  &.type5 {
    width           : 40%;
    padding         : 15px;
    color           : $color-white;
    background-color: rgba($color-black, 0.35);
    @include absolute(0, null, 0, 0);
    @include flex(flex-end);
  }

  &.type6 {
    width           : 40%;
    padding         : 15px;
    color           : $color-white;
    background-color: rgba($color-black, 0.35);
    @include absolute(0, 0, 0, null);
    @include flex(flex-start, flex-end);

  }

  &.type7 {
    width           : 40%;
    padding         : 15px;
    color           : $color-white;
    background-color: rgba($color-black, 0.35);
    @include absolute(0, 0, 0, null);
    @include flex(center, flex-end);
    text-align: right;
  }

  &.type8 {
    width           : 40%;
    padding         : 15px;
    color           : $color-white;
    background-color: rgba($color-black, 0.35);
    @include absolute(0, 0, 0, null);
    @include flex(flex-end, flex-end);

    text-align: right;
  }

  &.type9 {
    width          : 40%;
    padding        : 15px;
    backdrop-filter: blur(13px);
    color          : $color-white;
    background     : rgba($color-black, 0.1);
    @include absolute(0, null, 0, 0);
    @include flex(flex-start);
    text-align: right;
  }

  &.type10 {
    width          : 40%;
    padding        : 15px;
    backdrop-filter: blur(13px);
    color          : $color-white;
    background     : rgba($color-black, 0.1);
    @include absolute(0, null, 0, 0);
    @include flex(center);
  }

  &.type11 {
    width          : 40%;
    padding        : 15px;
    backdrop-filter: blur(13px);
    color          : $color-white;
    background     : rgba($color-black, 0.1);
    @include absolute(0, null, 0, 0);
    @include flex(flex-end);
  }

  &.type12 {
    width          : 40%;
    padding        : 15px;
    backdrop-filter: blur(13px);
    color          : $color-white;
    background     : rgba($color-black, 0.1);
    @include absolute(0, 0, 0, null);
    @include flex(flex-start, flex-end);
  }

  &.type13 {
    width          : 40%;
    padding        : 15px;
    backdrop-filter: blur(13px);
    color          : $color-white;
    background     : rgba($color-black, 0.1);
    @include absolute(0, 0, 0, null);
    @include flex(center, flex-end);
    text-align: right;
  }

  &.type14 {
    width          : 40%;
    padding        : 15px;
    backdrop-filter: blur(13px);
    color          : $color-white;
    background     : rgba($color-black, 0.1);
    @include absolute(0, 0, 0, null);
    @include flex(flex-end, flex-end);
    text-align: right;
  }

  &.type15 {
    padding: 15px;
    color  : $color-black;
    @include absolute(0, 0, 0, 0);
    @include flex(flex-start);
    text-align: right;

    p {
      max-width       : 30%;
      border-radius   : 4px;
      padding         : 10px;
      background-color: $color-white;
    }
  }

  &.type16 {
    padding: 15px;
    color  : $color-black;
    @include absolute(0, 0, 0, 0);
    @include flex(center);

    p {
      max-width       : 30%;
      border-radius   : 4px;
      padding         : 10px;
      background-color: $color-white;
    }
  }

  &.type17 {
    padding: 15px;
    color  : $color-black;
    @include absolute(0, 0, 0, 0);
    @include flex(flex-end);

    p {
      max-width       : 30%;
      border-radius   : 4px;
      padding         : 10px;
      background-color: $color-white;
    }
  }

  &.type18 {
    padding: 15px;
    color  : $color-black;
    @include absolute(0, 0, 0, 0);
    @include flex(flex-start);

    p {
      line-height  : 2;
      max-width    : 75%;
      border-radius: 4px;

      span {
        border-radius   : 4px;
        padding         : 4px;
        background-color: $color-white;

        -webkit-box-decoration-break: clone;
        -o-box-decoration-break     : clone;
        box-decoration-break        : clone;
      }
    }
  }

  &.type19 {
    padding: 15px;
    color  : $color-black;
    @include absolute(0, 0, 0, 0);
    @include flex(center);

    p {
      line-height  : 2;
      max-width    : 75%;
      border-radius: 4px;

      span {
        border-radius   : 4px;
        padding         : 4px;
        background-color: $color-white;

        -webkit-box-decoration-break: clone;
        -o-box-decoration-break     : clone;
        box-decoration-break        : clone;
      }
    }
  }

  &.type20 {
    padding: 15px;
    color  : $color-black;
    @include absolute(0, 0, 0, 0);
    @include flex(flex-end);

    p {
      line-height  : 2;
      max-width    : 75%;
      border-radius: 4px;

      span {
        border-radius   : 4px;
        padding         : 4px;
        background-color: $color-white;

        -webkit-box-decoration-break: clone;
        -o-box-decoration-break     : clone;
        box-decoration-break        : clone;
      }
    }
  }

  &.type21 {
    width     : 50%;
    padding   : 20px;
    text-align: center;
    color     : $color-black;
    background: rgba($color-white, 0.8);
    @include absolute(20px, null, 20px, 20px);
    @include flex(center);
  }

  &.type22 {
    padding   : 20px;
    text-align: center;
    color     : $color-black;
    background: rgba($color-white, 0.8);
    @include absolute(20px, 20px, 20px, 20px);
    @include flex(center, center);
  }

  &.type23 {
    width     : 50%;
    padding   : 20px;
    text-align: center;
    color     : $color-black;
    background: rgba($color-white, 0.8);
    @include absolute(20px, 20px, 20px, null);
    @include flex(center);
  }

  &.type24 {
    width     : 50%;
    padding   : 20px;
    text-align: center;
    color     : $color-white;
    border    : 15px solid rgba($color-white, 0.8);
    @include absolute(20px, null, 20px, 20px);
    @include flex(center);
  }

  &.type25 {
    padding   : 20px;
    text-align: center;
    color     : $color-white;
    border    : 15px solid rgba($color-white, 0.8);
    @include absolute(20px, 20px, 20px, 20px);
    @include flex(center, center);
  }

  &.type26 {
    width  : 50%;
    padding: 20px;
    color  : $color-white;
    border : 15px solid rgba($color-white, 0.8);
    @include absolute(20px, 20px, 20px, null);
    @include flex(center);
  }

  &.type27 {
    font-weight   : 900;
    padding       : 20px;
    letter-spacing: 0.7em;
    text-transform: uppercase;
    color         : $color-white;
    @include absolute(0, 0, 0, 0);
    text-align: center;
    @include flex(flex-start, center);
  }

  &.type28 {
    padding       : 20px;
    font-weight   : 900;
    text-align    : center;
    color         : $color-white;
    letter-spacing: 0.7em;
    text-transform: uppercase;
    text-align    : center;

    @include absolute(0, 0, 0, 0);
    @include flex(center, center);
  }

  &.type29 {
    padding       : 20px;
    font-weight   : 900;
    color         : $color-white;
    letter-spacing: 0.7em;
    text-transform: uppercase;
    text-align    : center;
    @include absolute(0, 0, 0, 0);
    @include flex(flex-end, center);
  }

  &.type30 {
    @include responsive(padding, 30px, null, 12px);

    font-weight   : 900;
    color         : $color-white;
    text-transform: uppercase;
    text-shadow   : 0px 0px 4px rgba($color-black, 0.12);
    @include responsive(font-size, 76px, null, 30px);
    font-weight: 900;
    @include responsive(line-height, 88px, null, 32px);

    letter-spacing: 1.52px;
    @include absolute(0, 0, 0, 0);
    @include flex(flex-end, space-between);

    p {
      @include responsive(margin-left, 30px, null, 0px);

      max-width: 60%;
    }
  }

  &.type31 {
    @include responsive(padding, 30px, null, 12px);

    font-weight   : 900;
    color         : $color-white;
    letter-spacing: 2.4px;
    text-transform: uppercase;
    @include responsive(font-size, 84px, null, 30px);
    line-height: normal;
    @include absolute(0, 0, 0, 0);
    @include flex(flex-start, space-between);

    p {
      @include responsive(margin-left, 30px, null, 0px);

      max-width: 66%;
    }
  }

  &.type32 {
    font-weight: 400;
    color      : $color-white;
    @include responsive(letter-spacing, 2.4px, null, normal);

    @include responsive(font-size, 48px, null, 20px);
    @include responsive(line-height, 60px, null, 30px);
    @include responsive(padding, 28px, null, 10px);

    font-weight   : 400;
    text-transform: uppercase;
    @include absolute(0, 0, 0, 0);
    @include flex(flex-end, center);


    p {
      width          : 100%;
      padding        : 20px;
      border-radius  : 24px;
      background     : rgba($color-tealGreen, 0.94);
      backdrop-filter: blur(1.5px);
      display        : flex;
      justify-content: space-between;
      text-align     : left;

      span {
        max-width: 80%;
        word-wrap: break-word;
      }
    }
  }

  &.type33 {

    padding: 30px;
    @include responsive(padding, 30px, 15px);

    color: $color-white;
    @include responsive(font-size, 34px, 12px);
    font-weight   : 700;
    line-height   : normal;
    text-transform: uppercase;
    @include absolute(0, 0, 0, 0);
    @include flex(flex-end, space-between);

    p {
      svg {
        @include responsive(font-size, null, null, 14px);
      }

      .avatar {
        @include responsive(width, 48px, 24px);
        @include responsive(height, 48px, 24px);

        border: 1px solid $color-white;
      }

      .userName {
        margin-top: 11px;
        @include responsive(font-size, 16px, 12px);

        font-style    : normal;
        font-weight   : 600;
        line-height   : normal;
        letter-spacing: 0.16px;
        opacity       : 0.8;
      }

      display       : flex;
      flex-direction: column;
    }
  }

  &.type34 {
    padding       : 24px;
    color         : $color-white;
    text-transform: uppercase;
    @include absolute(0, 0, 0, 0);
    @include flex(center, flex-end);
    background: linear-gradient(90deg, rgba($color-black, 0) 27.22%, rgba($color-black, 0.93) 100%);
    text-align: right;
    @include responsive(font-size, 32px, 10px);
    font-weight: 700;
    @include responsive(letter-spacing, 1.28px, 1px);

    line-height: normal;

    .emoji {
      @include flex(center, center);
      padding: 12px;
      @include responsive(border-radius, 16px, 5px);

      @include responsive(width, 60px, 20px);
      @include responsive(height, 60px, 20px);

      margin-bottom: 16px;
      @include responsive(margin-bottom, 16px, 8px);

      background-color: $color-white !important;
    }

    span {
      display: block;
    }

    .description {
      text-align: right;
      @include responsive(font-size, 14px, 5px);
      font-style : normal;
      font-weight: 500;
      line-height: normal;
      @include responsive(letter-spacing, 0.28px, 0);

      text-transform: none;
      opacity       : 0.8;
      @include responsive(width, 54%, 65%);

    }

    p {
      @include flex(flex-end, center, column);
      max-width: 46%;
    }
  }

  &.type35 {
    padding       : 30px;
    color         : $color-white;
    text-transform: uppercase;
    text-align    : center;
    font-weight   : 400;
    text-shadow   : 0px 0px 8px rgba($color-black, 0.12);
    @include responsive(font-size, 62px, 20px);

    svg {
      @include responsive(width, null, 80px);
    }

    line-height   : normal;
    letter-spacing: 1.86px;
    @include absolute(0, 0, 0, 0);
  }

  &.double,
  &.triple,
  &.tile {

    &.type1,
    &.type2,
    &.type3,
    &.type4,
    &.type4,
    &.type6,
    &.type7,
    &.type8,
    &.type9,
    &.type10,
    &.type11,
    &.type12,
    &.type13,
    &.type14,
    &.type15,
    &.type16,
    &.type17,
    &.type18,
    &.type19,
    &.type20,
    &.type21,
    &.type22,
    &.type23,
    &.type24,
    &.type25,
    &.type26,
    &.type27,
    &.type28,
    &.type29 {
      @include responsive(line-height, 26px, 16px);
      @include responsive(font-size, 20px, 14px);
    }


  }

  &.double {
    @include responsive(zoom, null, null, 0.45);

    &.type30 {
      zoom: 0.75;
      @include responsive(font-size, 76px, null, 26px);

      p {
        margin-left: 0px;
      }
    }

    &.type31 {

      @include responsive(font-size, 84px, null, 26px);

      p {
        margin-left: 0px;
      }

      zoom: 0.75;
    }

    &.type32 {
      zoom: 0.6;
    }

    &.type33 {
      zoom: 0.75;
    }

    &.type34 {

      zoom: 0.70;
    }

    &.type35 {
      zoom: 0.65;
    }

  }

  &.triple {
    zoom: 0.4;

    &.type1 {
      zoom: 0.4;
    }

    &.type2 {
      zoom: 0.4;
    }

    &.type3 {
      zoom: 0.4;
    }

    &.type12 {
      zoom: 0.4;
    }


    &.type30 {
      zoom: 0.4;

      p {
        margin-left: 0px;
      }
    }

    &.type31 {

      zoom: 0.4;

      p {
        margin-left: 0px;
      }
    }

    &.type32 {

      zoom: 0.40;
    }

    &.type33 {

      zoom: 0.5;
    }

    &.type34 {

      zoom: 0.4;
    }

    &.type35 {
      zoom: 0.5;
    }
  }

  &.tile {

    @include responsive(zoom, null, null, 0.5);

    &.type30 {
      zoom: 0.50;

      p {
        margin-left: 0px;
      }
    }

    &.type31 {
      zoom: 0.5;

      p {
        margin-left: 0px;
      }
    }

    &.type32 {
      zoom: 0.6;
    }

    &.type33 {
      zoom: 0.75;
    }

    &.type34 {
      zoom: 0.70;
    }

    &.type35 {
      zoom: 0.75;
    }
  }
}