
          @import "src/Core/styles/index.scss";
        
.popover {
  z-index: 1001 !important;

  @include phone {
    top      : 50% !important;
    left     : 50% !important;
    position : fixed !important;
    transform: translateX(-50%) translateY(-50%);
  }
}

.picker {
  max-width: 100%;
  width    : 334px;
  padding  : 0 !important;

  .container {

    .list {
      overflow-y: auto;
      max-height: 250px;
      padding   : 0 5px 20px 5px;
      @include hideScrollbar();

      h3 {
        font-size: 14px;
        margin   : 10px 0 5px 5px;
      }

      span {
        width     : 36px;
        height    : 36px;
        font-size : 26px;
        transition: all .2s;
        cursor    : pointer;
        @include inlineFlex(center, center);

        &:hover {
          border-radius   : 18px;
          background-color: $color-veryLightPinkTwo;
        }
      }
    }

    .category {
      padding   : 8px;
      border-top: 1px solid $color-brightGray;
      @include flex(center, space-between);

      span {
        width     : 36px;
        height    : 36px;
        font-size : 18px;
        transition: all .2s;
        cursor    : pointer;
        color     : rgba($color-blueyGrayTwo, 0.5);
        @include inlineFlex(center, center);

        &:hover {
          border-radius   : 18px;
          background-color: $color-veryLightPinkTwo;
        }

        &.active {
          color: $color-primary;

        }
      }
    }
  }
}