
          @import "src/Core/styles/index.scss";
        
.academyListFilter {
  z-index            : 11;
  background-repeat  : repeat;
  position           : relative;
  background-position: bottom left;
  background-color   : $color-black;

  .academyListFilterContent {
    gap: 10px;
    @include flex(center, space-between);
    @include content(1200px, 100%);

    ul,
    span {

      >li {
        color: $color-white;
      }
    }

    button {
      color: $color-white;

      &:hover,
      &:active {
        color: $color-white;
      }
    }

    .academyListFilterSelect {
      position: relative;
      @include responsive(top, -10px, unset);
    }
  }
}