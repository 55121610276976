
          @import "src/Core/styles/index.scss";
        
.landingFaq {
  padding-top     : 44px;
  padding-bottom  : 168px;
  background-color: $color-white;

  .landingFaqContent {
    @include content(1200px, 100%);

    .landingFaqFilter {
      margin-bottom   : 24px;
      background-color: transparent;
      @include responsive(width, null, calc(100% + 40px));
      @include responsive(margin-left, null, -20px);

      >div {
        @include responsive(max-width, null, 100%);
      }

      ul {
        gap: 12px;
        @include responsive(padding, null, 0 20px);

        li {
          border-radius : 30px;
          padding       : 12px 16px;
          text-transform: capitalize;
          color         : $color-black;
          border        : 1px solid $color-limeGreen;
          background    : rgba($color-limeGreen, 0.16);

          &[role=active] {
            background-color: $color-limeGreen;
          }
        }
      }
    }

    .landingFaqCard {
      margin-bottom   : 16px;
      background-color: $color-veryLightGray;
    }

    .landingFaqButton {
      margin-top: 32px;
      text-align: center;

      button {
        @include responsive(font-size, 20px, 16px);
        @include responsive(padding, 20px 48px, 16px 24px);

        svg {
          @include responsive(font-size, null, 14px);
        }
      }
    }
  }
}