
          @import "src/Core/styles/index.scss";
        
.container {
  font-weight    : 500;
  max-width      : 100%;
  border-radius  : 40px;
  text-align     : center;
  justify-content: center;
  transition     : all 0.3s;
  position       : relative;
  display        : inline-flex;
  border         : 1px solid transparent;
  @include responsive(font-size, 14px, 14px);
  @include responsive(padding, 6px 8px);

  .content {
    @include inlineFlex(center, center);
  }

  &.auto {
    @include responsive(padding, 10px, 8px);
  }

  &.block {
    width: 100%;
  }

  &.circle {
    border-radius: 50px;
    padding      : 8px !important;
  }

  &.loading {
    .content {
      opacity: 0;
    }
  }

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &.primary {
    color           : $color-white;
    border-color    : $color-primary;
    background-color: $color-primary;

    &:hover {
      background-color: lighten($color-primary, 10%);
    }

    &:active {
      background-color: lighten($color-primary, 15%);
    }

    &:disabled {
      opacity: 0.3;
    }
  }

  &.secondary {
    color           : $color-black;
    border-color    : $color-limeGreen;
    background-color: $color-limeGreen;

    &:hover {
      background-color: lighten($color-limeGreen, 10%);
    }

    &:active {
      background-color: lighten($color-limeGreen, 15%);
    }

    &:disabled {
      color           : $color-white;
      border-color    : rgba($color-black, 0);
      background-color: rgba($color-black, 0.1);
    }
  }

  &.light {
    color           : $color-black;
    border-color    : $color-lightGray;
    background-color: $color-lightGray;

    &:hover {
      background-color: lighten($color-lightGray, 10%);
    }

    &:active {
      background-color: lighten($color-lightGray, 15%);
    }

    &:disabled {
      color           : $color-white;
      border-color    : rgba($color-black, 0);
      background-color: rgba($color-black, 0.1);
    }
  }

  &.outline {
    color           : $color-black;
    border-color    : $color-black;
    background-color: transparent;

    &:hover {
      background-color: rgba($color-black, 0.1);
    }

    &:active {
      background-color: rgba($color-black, 0.15);
    }

    &:disabled {
      border-color: rgba($color-black, 0.6);
      color       : rgba($color-black, 0.6);
    }
  }

  &.whiteOutline {
    background-color: transparent;
    color           : $color-white;
    border-color    : $color-white;

    &:hover {
      background-color: rgba($color-white, 0.1);
    }

    &:active {
      background-color: rgba($color-white, 0.15);
    }

    &:disabled {
      border-color: rgba($color-black, 0.6);
      color       : rgba($color-black, 0.6);
    }
  }

  &.lightGray {
    border-color    : $color-mistGray;
    background-color: $color-snowGray;
    color           : $color-black;

    &:hover {
      background-color: lighten($color-snowGray, 10%);
    }

    &:active {
      background-color: lighten($color-snowGray, 15%);
    }

    &:disabled {
      color           : $color-white;
      border-color    : rgba($color-black, 0);
      background-color: rgba($color-black, 0.1);
    }
  }

  &.whiteOverlay {
    color           : $color-white;
    background-color: rgba($color-white, 0.3);

    &:hover {
      background-color: rgba($color-white, 0.4);
    }

    &:active {
      background-color: rgba($color-white, 0.5);
    }

    &:disabled {
      color           : $color-white;
      border-color    : rgba($color-black, 0);
      background-color: rgba($color-black, 0.1);
    }
  }

  &.dark {
    background-color: $color-black;
    color           : $color-white;

    &:hover {
      background-color: darken($color-black, 10%);
    }

    &:active {
      background-color: darken($color-black, 15%);
    }

    &:disabled {
      color           : $color-white;
      border-color    : rgba($color-black, 0);
      background-color: rgba($color-black, 0.1);
    }
  }

  &.darkLight {
    color           : $color-black;
    border-color    : $color-black;
    background-color: rgba($color-black, 0.1);

    &:hover {
      background-color: darken($color-black, 20%);
    }

    &:active {
      background-color: darken($color-black, 25%);
    }

    &:disabled {
      color           : $color-white;
      border-color    : rgba($color-black, 0);
      background-color: rgba($color-black, 0.1);
    }
  }

  &.text {
    padding         : 0;
    border-radius   : 0;
    width           : auto;
    border          : none;
    color           : $color-black;
    background-color: transparent;

    &:hover {
      color: darken($color-black, 10%);
    }

    &:active {
      color: darken($color-black, 15%);
    }

    &:disabled {
      color: rgba($color-black, 0.5);
    }
  }

  &.whiteText {
    padding         : 0;
    border-radius   : 0;
    width           : auto;
    border          : none;
    color           : $color-white;
    background-color: transparent;

    &:hover {
      color: darken($color-white, 10%);
    }

    &:active {
      color: darken($color-white, 15%);
    }

    &:disabled {
      color: rgba($color-white, 0.5);
    }
  }

  .before {
    margin-right: 8px;
    font-size   : 16px;
    @include inlineFlex();
  }

  .after {
    margin-right: 4px;
    font-size   : 16px;
    margin-left : 10px;
    @include inlineFlex();
  }

  .loading {
    z-index      : 2;
    color        : inherit;
    border-radius: inherit;
    @include absolute(0, 0, 0, 0);
    @include flex(center, center);

    >svg {
      margin-right: unset !important;
    }
  }
}