
          @import "src/Core/styles/index.scss";
        
.menu {
  gap       : 4px;
  overflow-x: auto;
  @include hideScrollbar();
  @include flex(center);

  &.disabled {
    opacity       : 0.5;
    pointer-events: none;
  }

  &.desktopHidden {
    @include responsive(display, null, none);
  }
}

.menuItem {
  letter-spacing: 3%;
  gap           : 8px;
  font-weight   : 500;
  font-size     : 16px;
  line-height   : 20px;
  list-style    : none;
  white-space   : nowrap;
  transition    : all .2s;
  cursor        : pointer;
  padding       : 16px 24px;
  text-transform: uppercase;
  color         : $color-black;
  border-bottom : 4px solid transparent;
  @include flex(center);

  .menuItemArrow {
    width        : 22px;
    height       : 22px;
    border-radius: 22px;
    font-size    : 10px;
    color        : $color-white;
    background   : $color-primary;
    @include flex(center, center);
  }

  &.active {
    font-weight : 700;
    border-color: $color-white;
  }

  &.subMenuItem {
    border       : none;
    border-radius: 32px;
    padding      : 12px 16px;
    color        : $color-black;

    &:hover {
      color           : $color-white;
      background-color: $color-primary;
    }
  }
}

.menuDropdown {
  @include responsive(display, none !important, block !important);

  .menuDropdownButton {
    border-radius   : 0;
    font-weight     : 700;
    border          : none;
    text-transform  : uppercase;
    background-color: transparent;
    color           : $color-black;
    padding         : 12px 0 !important;
    border-bottom   : 4px solid $color-black !important;

    >div>span {
      width           : 24px;
      height          : 24px;
      border-radius   : 24px;
      font-size       : 10px;
      background-color: $color-black;
      color           : $color-white;
      @include flex(center, center);
    }
  }
}

.popover {
  margin-top: -20px;

  .popoverContent {
    border-radius: 16px;
    min-width    : 200px;
    padding      : 4px !important;
    border       : 2px solid $color-black;

    &::before {
      display: none;
    }
  }
}