
          @import "src/Core/styles/index.scss";
        
.likeDislike {
  margin-top      : auto;
  text-align      : center;
  background-color: $color-primary;
  @include responsive(border-radius, 0 0 24px 24px, 0);
  @include responsive(padding, 32px, 20px);

  .likeDislikeTitle {
    font-weight  : 700;
    font-size    : 16px;
    margin-bottom: 24px;
    line-height  : 24px;
    color        : $color-white;
  }

  .likeDislikeButtons {
    gap: 20px;
    @include flex(center, center);

    button {
      font-size: 26px;
      @include responsive(height, 64px, 48px);
      @include responsive(width, 64px, 48px);
      @include flex(center, center);
    }
  }
}