
          @import "src/Core/styles/index.scss";
        
.forumThreadCommentsItemMenu {
  margin-left: auto;

  .forumThreadCommentsItemMenuButton {
    width    : 44px;
    height   : 44px;
    font-size: 16px;
    @include flex(center, center);
  }

  .forumThreadCommentsItemMenuDanger {
    font-weight: 500;
    color      : $color-red;
  }

  [role=dialog] {
    min-width: 200px;
  }
}