
          @import "src/Core/styles/index.scss";
        
.howToSidebar {
  flex-shrink: 0;
  gap        : 16px;
  @include responsive(padding, null, 0 20px);
  @include responsive(width, 300px, 100%);
  @include flex(null, null, column);

  &.selected {
    @include responsive(display, null, none);
  }
}