
          @import "src/Core/styles/index.scss";
        
.container {
  >label {
    font-weight  : 700;
    font-size    : 14px;
    margin-bottom: 10px;
    display      : block;
  }

  >section {
    position: relative;

    .before {
      border-radius   : 36px;
      font-size       : 16px;
      background-color: $color-black;
      color           : $color-white;
      @include inlineFlex(center, center);
      @include responsive(width, 36px, 32px);
      @include responsive(height, 36px, 32px);
      @include absolute(null, null, null, 8px);
      @include responsive(top, 9px, 8px);
    }

    >input {
      width           : 100%;
      outline         : none;
      font-weight     : bold;
      border-radius   : 30px;
      display         : block;
      background-color: $color-white;
      caret-color     : $color-primary;
      border          : 2px solid $color-black;
      @include responsive(padding, 16px, 12px);
      @include responsive(font-size, 14px, 16px);

      &:disabled {
        color : $color-slateGray;
        border: 1px solid rgba($color-blueyGrayTwo, 0.25);
      }
    }

    ::placeholder {
      font-weight: normal;
      color      : $color-black;
    }

    .after {
      display  : flex;
      transform: translateY(-50%);
      @include absolute(50%, 8px);

      button {
        font-weight  : 800;
        border-radius: 8px;
        width        : auto;
        font-size    : 12px;
        padding      : 8px 20px;
      }

      .password {
        padding  : 8px;
        font-size: 16px;
      }
    }

    .length {
      font-weight     : 600;
      border-radius   : 20px;
      font-size       : 16px;
      padding         : 4px 8px;
      background-color: $color-black;
      color           : $color-white;
      transform       : translateY(-50%);
      @include absolute(50%, 14px);
    }
  }

  >p {
    margin-bottom: 0;
    margin-top   : 5px;
    font-size    : 12px;
  }

  &.error {
    >section {
      >input {
        color       : $color-red;
        border-color: $color-red;
      }

      ::placeholder {
        font-weight: normal;
        color      : rgba($color-red, 0.5);
      }
    }
  }

  &.success {
    >section {
      >input {
        color       : $color-algaeGreen;
        border-color: $color-algaeGreen;
      }

      ::placeholder {
        font-weight: normal;
        color      : rgba($color-algaeGreen, 0.5);
      }
    }
  }

  &.info {
    >section {
      >input {
        color       : $color-paleGold;
        border-color: $color-paleGold;
      }

      ::placeholder {
        font-weight: normal;
        color      : rgba($color-paleGold, 0.5);
      }
    }
  }

  &.before {
    >section {
      >input {
        padding-left: 52px;
      }
    }
  }
}