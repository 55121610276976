
          @import "src/Core/styles/index.scss";
        
.audioPlayer {
  overflow     : hidden;
  border-radius: 20px;
  border       : 1px solid $color-athensGray;

  .preview {
    background-color: $color-white;
    @include responsive(padding, 20px 20px 36px 20px, 10px 10px 24px 10px);
    position: relative;

    .current {
      position: absolute;
      @include responsive(font-size, 13px, 11px);
      @include responsive(bottom, 12px, 4px);
      @include responsive(left, 20px, 10px);
    }

    .length {
      position: absolute;
      @include responsive(font-size, 13px, 11px);
      @include responsive(bottom, 12px, 4px);
      @include responsive(right, 20px, 10px);
    }
  }

  .controls {
    gap             : 40px;
    padding         : 10px;
    background-color: $color-athensGray;
    @include flex(center, center);
  }
}