
          @import "src/Core/styles/index.scss";
        
.landingIntro {
  overflow: hidden;
  position: relative;

  @include global {
    background-image: url("/Landing/assets/intro.jpg");
  }

  .landingIntroAnimation {
    @include responsive(margin-left, null, calc(50vw - 550px));

    >div {
      @include responsive(width, 1440px !important, 1100px !important);
      @include responsive(height, 920px !important, 690px !important);
    }
  }

  .landingIntroContent {
    text-align: center;
    @include content(554px, 234px);
    @include absoluteCenter();

    h1 {
      font-weight  : 700;
      margin-bottom: 24px;
      line-height  : normal;
      color        : $color-white;
      @include responsive(font-size, 36px, 24px);

      span {
        line-height: 1;
        font-weight: 700;
        @include responsive(font-size, 72px, 32px);
        color                  : $color-primary;
        background             : linear-gradient(90deg, $color-primary 0%, $color-pineappleSalmon 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip        : text;
        text-fill-color        : transparent;
      }
    }

    h2 {
      font-weight: 400;
      line-height: 23px;
      text-align : center;
      color      : $color-white;
      @include responsive(font-size, 16px, 14px);

      span {
        font-weight: 500;

        &.bold {
          font-weight: 700;
        }
      }
    }
  }
}