
          @import "src/Core/styles/index.scss";
        
.container {
  gap: 12px;
  @include flex(flex-start);

  .avatar {
    border: 2px solid $color-primary;
    @include responsive(font-size, 16px, 12px);
    @include responsive(height, 32px, 25px);
    @include responsive(width, 32px, 25px);
  }

  .content {
    flex: 1;
    @include responsive(gap, 8px, 4px);
    @include flex(null, null, column);

    .title {
      @include responsive(line-height, 1.37, 1.42);
      @include responsive(font-size, 16px, 14px);

      span {
        font-weight: bold;
      }
    }

    .footer {
      font-size  : 11px;
      font-weight: 600;
      line-height: 1.27;
      color      : rgba($color-blueyGrayTwo, 1);
    }
  }
}