
          @import "src/Core/styles/index.scss";
        
.emptyContainer {
  position: relative;

  .emptyContainerContent {
    padding   : 40px;
    text-align: center;
    @include flex(center, center, column);

    &.loading {
      color: transparent;
    }
  }

  .emptyContainerLoading {
    z-index         : 100;
    font-size       : 32px;
    background-color: rgba($color-white, 0.5);
    color           : rgba($color-black, 0.5);
    @include flex(center, center);
    @include absolute(0, 0, 0, 0);
  }
}