
          @import "src/Core/styles/index.scss";
        
.pagination {
  margin-top: 40px;
  @include flex(center, space-between);

  button {
    height   : 36px;
    font-size: 14px;
    padding  : 8px 12px;
    @include responsive(width, null, 36px);

    svg {
      font-size: 16px;
    }

    span {
      @include responsive(margin-left, null, 0 !important);
      @include responsive(margin-right, null, 0 !important);
    }

    .text {
      @include responsive(display, null, none);
    }
  }

  .paginationPages {
    @include flex(center);
    @include responsive(display, null, none);

    .paginationPage {
      font-size    : 16px;
      border-radius: 40px;
      width        : 40px;
      height       : 40px;
      cursor       : pointer;
      @include flex(center, center);

      &.dot {
        cursor: default;
      }

      &.selected {
        font-weight     : 700;
        color           : $color-white;
        background-color: $color-pineappleSalmon;
      }
    }
  }

  .mobile {
    @include responsive(display, none, block);

    span {
      font-weight: 700;
    }
  }
}