
          @import "src/Core/styles/index.scss";
        
.avatar {
  border-radius   : 80px;
  overflow        : hidden;
  position        : relative;
  color           : $color-black;
  background-color: $color-athensGray;
  border          : 1px solid $color-black;
  @include flex(center, center);
}