
          @import "src/Core/styles/index.scss";
        
.faqListCard {
  cursor          : pointer;
  background-color: $color-white;
  @include responsive(border-radius, 24px, 16px);
  @include responsive(padding, 24px, 16px);
  @include responsive(gap, 16px, 12px);
  @include flex(null, null, column);

  .faqListCardHeader {
    @include flex(center, space-between);

    .faqListCardHeaderTitle {
      font-weight: 700;
      @include responsive(font-size, 24px, 20px);
      @include responsive(line-height, 30px, 25px);
    }

    .faqListCardHeaderRight {
      @include responsive(gap, 16px, 8px);
      @include flex(center);

      .faqListCardHeaderTag {
        font-weight     : 500;
        line-height     : 22px;
        border-radius   : 20px;
        white-space     : nowrap;
        border          : 1px solid $color-limeGreen;
        background-color: rgba($color-limeGreen, 0.1);
        @include responsive(padding, 9px 16px, 4px 12px);
        @include responsive(font-size, 16px, 12px);
      }

      button {
        @include responsive(font-size, 20px, 16px);
        @include responsive(height, 40px, 28px);
        @include responsive(width, 40px, 28px);
        @include flex(center, center);
      }
    }
  }

  .faqListCardContent {
    font-weight: 400;
    @include responsive(font-size, 16px, 14px);
    @include responsive(line-height, 26px, 20px);
  }
}