
          @import "src/Core/styles/index.scss";
        
.container {

  label {
    font-size    : 15px;
    font-weight  : bold;
    margin-bottom: 10px;
    display      : block;
    position     : relative;
  }

  @include global {
    .public-DraftEditorPlaceholder-root {
      color: rgba($color-black, 0.4);
    }
  }

  .input {
    padding   : 20px 20px 0 20px;
    position  : relative;
    word-break: break-word;
    @include responsive(font-size, 13px, 16px);

    &.open {
      padding: 20px 20px 60px 20px;
    }

    .draftjs {
      font-size            : 16px;
      -webkit-touch-callout: none;
      color                : $color-black;
      caret-color          : $color-black;

      * {
        -webkit-touch-callout: none;
      }

      [contenteditable=true] {
        min-height: 60px;
        text-align: inherit;
      }

      @include global {

        .DraftEditor-root,
        .public-DraftEditorPlaceholder-root {
          text-align: inherit;
        }
      }

      a {
        text-decoration: underline;
        color          : $color-primary;

        &:visited,
        &:hover,
        &:active {
          color: $color-primary;
        }
      }


    }

    .buttons {
      z-index: 3;
      @include absolute(null, 15px, 15px);
      @include flex(center);

      svg {
        font-size: 20px;
      }

      >span {
        display: flex;

        >button {
          margin-left: 8px;
          padding    : 12px;
        }
      }
    }

    .length {
      font-weight     : 600;
      border-radius   : 20px;
      font-size       : 16px;
      margin-left     : 12px;
      white-space     : nowrap;
      padding         : 4px 8px;
      color           : $color-white;
      background-color: $color-black;
    }
  }
}