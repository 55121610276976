
          @import "src/Core/styles/index.scss";
        
.guestMenu {
  gap: 12px;
  @include flex(center);

  a {

    button {
      font-weight: 600;
      font-size  : 16px;
      @include responsive(padding, 10px 24px, 10px 24px);
    }

    &.guestMenuRambly {
      @include responsive(display, null, none);
    }
  }

  .guestMenuMobile {

    &.dark {
      background-color: rgba($color-white, 0.1);

      svg {
        color: $color-white;
      }
    }
  }
}