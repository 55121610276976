
          @import "src/Core/styles/index.scss";
        
.container {
  gap: 12px;
  @include flex(flex-start);

  .avatar {
    @include responsive(font-size, 16px, 12px);
    @include responsive(height, 32px, 25px);
    @include responsive(width, 32px, 25px);
    border: 2px solid $color-primary;
  }

  .content {
    flex: 1;
    @include responsive(gap, 8px, 4px);
    @include flex(null, null, column);

    .title {
      @include responsive(line-height, 1.37, 1.42);
      @include responsive(font-size, 16px, 14px);

      span {
        font-weight: bold;
      }
    }

    .innerContent {
      @include flex(center);
      border-radius   : 6px;
      gap             : 8px;
      background-color: rgba($color-paleGreyFour, 0.4);
      @include responsive(padding, 12px, 8px);

      .image {
        img {
          border-radius: 4px;
          object-fit   : cover;
          aspect-ratio : 92 / 64;
          width        : 92px;
          height       : auto;
        }
      }

      .text {
        @include flex(flex-start, null, column);

        .textTitle {
          overflow          : hidden;
          line-clamp        : 2;
          display           : -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          font-weight       : 800;
          @include responsive(line-height, 1.28, 1.28);
          @include responsive(font-size, 14px, 13px);
        }

        .textSummary {
          overflow          : hidden;
          line-clamp        : 2;
          display           : -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          @include responsive(line-height, 1.3, 1.3);
          @include responsive(font-size, 10px, 10px);
        }
      }

    }

    .footer {
      font-size  : 11px;
      font-weight: 600;
      line-height: 1.27;
      color      : rgba($color-blueyGrayTwo, 1);
    }
  }
}