$font-family: Avant,
sans-serif;

$color-red              : #FB0000;
$color-pineappleSalmon  : #FC6264;
$color-veryLightPinkTwo : #E6E6E6;
$color-paleGold         : #DDCC66;
$color-algaeGreen       : #25C43F;
$color-limeGreen        : #C5F41B;
$color-tealGreen        : #5A9F97;
$color-primary          : #543BF7;
$color-blueyGrayTwo     : #8896A6;
$color-snowGray         : #FAFAFA;
$color-veryLightGray    : #F8F8F8;
$color-paleGray         : #F6F6F6;
$color-paleGreyFour     : #F1F2F6;
$color-brightGray       : #EFEFEF;
$color-athensGray       : #EDEFF2;
$color-mistGray         : #E3E3E3;
$color-lightGray        : #F3F3F3;
$color-slateGray        : #A7A9B4;
$color-white            : #FFFFFF;
$color-black            : #151515;



$color-twitch   : #6C2498;
$color-youtube  : #FF0000;
$color-twitter  : #000000;
$color-facebook : #4267B2;
$color-snapchat : #FFFC00;
$color-tiktok   : linear-gradient(to right, #00F2EA 0%, #FF004F 102%);
$color-instagram: linear-gradient(46deg, #FEC61C 2%, #EC001B 52%, #C0029D 100%);

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}