
          @import "src/Core/styles/index.scss";
        
.forumThreadCommentsItem {
  padding         : 24px;
  border-radius   : 24px;
  background-color: $color-veryLightGray;

  .forumThreadCommentsItemHeader {
    gap          : 20px;
    margin-bottom: 32px;
    @include flex(center);

    .forumThreadCommentsItemAvatar {
      width    : 60px;
      height   : 60px;
      font-size: 30px;
      border   : none;
    }

    .forumThreadCommentsItemUser {

      .forumThreadCommentsItemName {
        margin-bottom: 6px;
        font-weight  : 700;
        font-size    : 16px;
      }

      .forumThreadCommentsItemDate {
        font-weight: 500;
        font-size  : 14px;
        color      : rgba($color-black, 0.7);
      }
    }
  }

  .forumThreadCommentsItemMessage {
    word-break: break-word;
  }
}